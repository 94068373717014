.hakkimizda-bolumu-anasayfa {
    padding: 1rem 0 13rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 99rem 0 1rem;
        margin-top: -50rem;
    }
    @include responsive(tablet-kucuk) {
        padding: 0rem 0 0rem;
        margin-top: -24rem;
    }

}

.hakkimizda-bolumu-anasayfa1 {
    padding: 0rem 0 13rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 99rem 0 1rem;
        margin-top: -50rem;
    }
    @include responsive(tablet-kucuk) {
        padding: 0rem 0 0rem;
        margin-top: -24rem;
    }

}

// hakkımızda sayfası

.hakkimizda-bolumu-hakkimizda {
    padding: 5rem 0 1rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 0rem 0;
        margin-top: -55rem;
    }
    @include responsive(tablet-kucuk) {
        padding: 0rem 0;
        margin-top: -75rem;
    }
}

// Before Alanı

.before-bolumu-anasayfa {
    padding: 0rem 0 5rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 99rem 0 1rem;
        margin-top: -50rem;
    }
    @include responsive(tablet-kucuk) {
        padding: 20rem 0 2rem;
        margin-top: -24rem;
    }

}

// Before After Alanı

.before-bolumu {
    padding: 5rem 0 5rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 40rem 0 2rem;
    }
    @media screen and (min-width: 768px) and (max-width: 899px) {
        padding: 23rem 0 2rem;
    }

}

// Before After Service Alanı

.before-bolumu-service {
    padding: 0rem 0 2rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 99rem 0 1rem;
        margin-top: -50rem;
    }
    @include responsive(tablet-kucuk) {
        padding: 20rem 0 2rem;
        margin-top: -24rem;
    }

}


// count sayfası
.count-bolumu{
    padding: 0rem 0 0rem;
    background-color: #141414;

    @include responsive(tablet-kucuk) {
        padding: 99rem 0 1rem;
        margin-top: -50rem;
    }
    @include responsive(tablet-kucuk) {
        padding: 0rem 0 1rem;
        margin-top: -55rem;
    }

}

// 404 sayfası

.hata-404 {
    padding: 5rem 0 5rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 0rem 0;
        margin-top:20vh;
    }
    @include responsive(tablet-kucuk) {
        padding: 0rem 0;
        margin-top:-20vh;
    }
}

//hizmetler anasayfa alanı

.hizmetler-alani {
    padding: 6rem 0 5rem;
    background-image: linear-gradient(to right bottom, $beyaz-renk, #fbfbfb);

    @include responsive(tablet-kucuk) {
        padding: 10rem 0 17rem;
        margin-top: -20rem;
    }
}

// hizmetler paragraf alanı

.hizmetler-aciklama {
    padding: 6rem 0 5rem;
    background-image: linear-gradient(to right bottom, $beyaz-renk, #fbfbfb);

    @include responsive(tablet-kucuk) {
        padding: 10rem 0 17rem;
    }
}

//services1

.services1 {
    padding: 5rem 0 5rem;
    background-color: $beyaz-renk;
    background: url('../../images/service-term.jpg');

    @include responsive(tablet-kucuk) {
        padding: 10rem 0;
    }
}

//services2

.services2 {
    padding: 11rem 0 5rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 21rem 0;
    }
}

// home slider services

.hm-services2 {
    padding: 0rem 0 5rem;
    background-color: $beyaz-renk;
    margin-top: -17rem;

    @include responsive(tablet-kucuk) {
        padding: 10rem 0;
    }
}

// paketler

.paketler-alani {
    padding: 8rem 0 5rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 21rem 0;
    }
}

// yorumlar alanı
.yorumlar-alani {
    padding: 5rem 0 5rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 10rem 0;
    }
}
//post sayfası
.post-alani-sayfa {
    padding: 1rem 0 7rem;

    @include responsive(tablet-kucuk) {
        padding: 10rem 0;
    }
}

// yorumlar alan
.yorumlar-alani-sayfa {
    padding: 9rem 0 1rem;

    @include responsive(tablet-kucuk) {
        padding: 10rem 0;
    }
}

//ekip alanı
.ekip-alani-sayfa {
    padding: 0rem 0 1rem;

    @include responsive(tablet-kucuk) {
        padding: 10rem 0;
    }
}

.news-alani-sayfa {
    padding: 10rem 0 9rem;
    background: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 18rem 0;
    }
    @media screen and (min-width: 500px) and (max-width: 640px) {
        padding: 30rem 0;
    }
    @media screen and (min-width: 768px) and (max-width: 900px) {
        padding: 7rem 0;
    }
}

img.haber-gorsel {


    @include responsive(telefon) {
        width: 250px;
        text-align: center;
    }

    @include responsive(tablet-kucuk) {

        text-align: center;
    }

}


.yorumlar-alani {
    position: relative;
    padding: 5rem 0 5rem;
    background-image: linear-gradient(to right bottom, #ffffff, #fbfbfb);
    background-size: cover;

    @include responsive(tablet-kucuk) {
        padding: 5rem 0 0rem;
    }
}

.form-alani {
    padding: 40rem 0;
    background-image: linear-gradient(to right bottom,$anarenk-acik-mavi,$anarenk-koyu-mavi);
    margin-top: -30vh;

}

//projeler anasayfa için

.projeler {
    position: relative;
    padding: 5rem 0 5rem;
    background-image: linear-gradient(to right bottom, #ffffff, #fbfbfb);
    background-size: cover;

    @include responsive(tablet-kucuk) {
        padding: 15rem 0 16rem;
    }
}

// projeler sayfası için

.projeler-sayfa-alani {
    padding: 5rem 0 5rem;
    background-color: $beyaz-renk;
    /*margin-top: -10rem;*/

    @include responsive(tablet-kucuk) {
        padding: 15rem 0 16rem;
    }
}

// markalar

.markalar {
    padding: 3rem 0 2rem;
    background-image: linear-gradient(to right bottom, #141414, #0c0c0c);
    background-size: cover;
    display: inline-block;
    width: 100%;

    @include responsive(tablet-kucuk) {
        padding: 15rem 0 16rem;
    }
}

// bottom
.bottom {
    padding: 5rem 0 1rem;
    background-image: linear-gradient(to right bottom, #fffefe, #ffffff);
    background-size: cover;
    display: inline-block;
    width: 100%;

    @include responsive(tablet-kucuk) {
        padding: 15rem 0 16rem;
    }
}

// hizmetler detay sayfa

.hizmetler-detay-sayfasi-alani {
    padding: 11rem 0 5rem;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 0rem 0rem 20rem;
        //margin-top:20vh;
    }
    @include responsive(tablet-kucuk) {
        padding: 0rem 0rem 20rem;
        //margin-top:-20vh;
    }
}

// proje detay sayfası alanı

// hizmetler detay sayfa

.proje-detay-sayfasi-alani {
    padding: 5rem 0 1rem;
    /*margin-top:-20vh;*/
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 40rem 0rem 5rem;
        margin-top:20vh;
    }
    @include responsive(tablet-kucuk) {
        padding: 40rem 0rem 5rem;
        margin-top:-20vh;
    }
}

// İletişim İcon Alanı

.iletisim-icon-alani {
    padding: 20rem 0rem 1rem;
    margin-top:-20vh;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 15rem 0;
        margin-top:20vh;
    }
    @include responsive(tablet-kucuk) {
        padding: 15rem 0;
        margin-top:-20vh;
    }
}

// İletişim Form Alanı

.iletisim-form-alani {
    padding: 20rem 22px 10rem;
    margin-top:-20vh;
    background-color: $beyaz-renk;

    @include responsive(tablet-kucuk) {
        padding: 0rem 0;
        margin-top:20vh;
    }
    @include responsive(tablet-kucuk) {
        padding: 0rem 0;
        margin-top:-20vh;
    }
}
span.date {
    font-size: 1.4rem;
    font-weight: 700;
    color: #4c99d7;
    text-align: center;

    @include responsive(telefon) {
        font-size: 2.8rem;
    }
    @media (min-width: 600px) and (max-width: 900px) {
        font-size: 3.5rem;
    }
    @media (min-width: 900px) and (max-width: 1200px) {
        font-size: 2rem;
    }
}
span.category {
    font-size: 1.4rem;
    font-weight: 700;
    color: #6175c9;
    text-align: center;

    @include responsive(telefon) {
        font-size: 2.8rem;
    }
    @media (min-width: 600px) and (max-width: 900px) {
        font-size: 3.5rem;
    }
    @media (min-width: 900px) and (max-width: 1200px) {
        font-size: 2rem;
    }
}
span.tt {
    font-size: 1.4rem;
    font-weight: 700;
    color: #141414;
    text-align: center;

    @include responsive(telefon) {
        font-size: 2.8rem;
    }
    @media (min-width: 600px) and (max-width: 900px) {
        font-size: 3.5rem;
    }
    @media (min-width: 900px) and (max-width: 1200px) {
        font-size: 2rem;
    }
}
.datesection {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
