.open-btn{
    position: fixed;
    top: 26px;
    right: 109px;
    background: #ffffff url(../images/icon_search-ss.png) no-repeat 15px center;
    background-size: 35px 35px;
    width: 72px;
    height: 65px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    z-index: 8;

	@include responsive(telefon) {
		top: 11px;
		right: 54px;
	}

	@media (min-width: 641px) and (max-width: 900px) {
		top: 31px;
		right: 75px;
	}


}

#search-wrap{
    position:fixed;
	top: 0;
    left: 0;
	z-index: -1;
	opacity: 0;
	transition: all 0.4s;
	width:100%;
	height: 100vh;
}

#search-wrap.panelactive{
	opacity: 1;
	 z-index: 888;
	 background:#fff;

	 display: flex;
	 justify-content: center;
	 align-items: center;
}


#search-wrap .search-area{
	display: none;
}


#search-wrap.panelactive .search-area{
	display: block;
	width:80%;
	position: relative;
}


#search-wrap form{
	position: relative;
	height: 66px;
}

#search-wrap input{
	-webkit-appearance:none;
	outline: none;
	cursor: pointer;
	color: #666;
}

 #search-wrap input[type="text"] {
	width: 100%;
	padding: 20px;
	border: none;
	border-bottom:2px solid #666;
	transition: all 0.5s;
	letter-spacing: 0.05em;
    font-size: 2rem;
    font-weight: 600;
}

 #search-wrap input[type="text"]:focus {
	background:#fff;
}

 #search-wrap input[type="submit"] {
	position: absolute;
	 top:0;
	right:10px;
	background:url("../images/icon_search-s.png") no-repeat 15px center;
	background-size: 25px 25px;
	width:60px;
	height: 60px;
    border: none;
}


.close-btn{
	position: absolute;
	top:10px;
	right:10px;
	z-index: 2;
	cursor: pointer;
    width: 60px;
    height:60px;
    background: #8a8a99;
}

.close-btn span{
    display: inline-block;
    position: absolute;
    left: 14px;
    height: 3px;
    border-radius: 2px;
	background-color: #fff;
 }


.close-btn span:nth-of-type(1) {
    top: 21px;
    left: 16px;
    transform: translateY(6px) rotate(-135deg);
    width: 50%;
}

.close-btn span:nth-of-type(2){
    top: 32px;
    left: 16px;
    transform: translateY(-6px) rotate(135deg);
    width: 50%;
}
