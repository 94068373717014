.main-menu {
    .dropdown-toggle::after {
        display: none !important;
    }
    .dropdown {
        .dropdown-menu {
            top: 87px;
            right: -25px;
            min-width: 280px;
            //border: 0;
            //border-radius: 0;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;

            .dropdown-item {
                margin: 16px 12px;

                &:last-of-type {
                    border-bottom: none;
                }

                &.active {
                    color: $anarenk-koyu-blue;
                }
            }
        }
    }
}
.navbar {
    padding: 15px;
    .logo {
        a {
            img {
                height: 82px;
            }
        }
    }
}
.page-teaser {
    padding-top: 112px;
    z-index: 2;
    @media (max-width: 767px) {
        display: none;
    }
}
.nav-shadow {
    width: 100%;
    z-index: 1;
    position: fixed;
    top: calc(112px - 1rem);
    height: 1rem;
    background-color: white;
}
header {
    height: auto !important;
    min-height: 112px;
}
