// Fonts
@import "fonts";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// FontAwesome
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';

// DataTables
@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";

// Dropzone
//@import "~dropzone/dist/dropzone.css";

// Mediacare
@import "mediacare/layout";

@import 'datepicker';
@import "form";
@import "popover";
@import "navigation";
@import "user";
@import "cookie-consent";

.app {
    overflow: hidden;
}

a {
    color: inherit;
}

#pointer-ring, #pointer-dot {
    display: none;
}

header {
    height: 112px;
}

main {
    padding-top: 66px;
    padding-bottom: 66px;
}

.sub-main-padding {
    margin-top: -66px;
    margin-bottom: -66px;
}

.sub-main-padding-top {
    margin-top: -66px;
}

.sub-main-padding-bottom {
    margin-bottom: -66px;
}

.btn.btn-link {
    font-size: inherit;
    line-height: inherit;
}

a.no-hover:hover {
    color: inherit !important;
}

.flex-1 {
    flex: 1;
}

.flex-basis-unset {
    flex-basis: unset !important;
}

.flex-basis-80 {
    flex-basis: 80% !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.position-unset {
    position: unset !important;
}

.w-90 {
    width: 90% !important;
}

.w-80 {
    width: 80% !important;
}

.w-60 {
    width: 60% !important;
}

.w-45 {
    width: 45% !important;
}

.w-30 {
    width: 30% !important;
}

.w-33 {
    width: 33% !important;
}

.h-5 {
    height: 3rem !important;
}

h1.mb-0, h2.mb-0, h3.mb-0 {
    margin-bottom: 0 !important;
}

.bg-white-opacity-d {
    background-color: #fffd !important;
}

.me-5-percent {
    margin-right: 5% !important;
}

.pe-6 {
    padding-right: 6rem !important;
}

.font-size-18px {
    font-size: 18px !important;
}

.z-index-1 {
    z-index: 1 !important;
}

.right-0 {
    right: 0;
}

.text-link {
    color: $anarenk-koyu-mavi !important;
    text-decoration: none !important;
    &:hover {
        text-decoration: underline !important;
    }
}

.textarea-h-100 {
    height: calc(100% - 2.7rem);
}

.bg-acik-blue {
    background-color: $anarenk-acik-blue !important;
}

.slider .swiper-container .slide-inner, .slider .swiper-container .slide-inner {
    padding: 0 calc(50% - 1.5rem) 0 calc(100px - 1.5rem);
}
.slider .swiper-container .slide-inner .box, .slider .swiper-container .slide-inner .box {
    background: #fffb;
    padding: 3rem 1.5rem;
    border-radius: 5px;
}
.slider .swiper-container .slide-inner h2, .slider .swiper-container .slide-inner .h2 {
    //filter: drop-shadow(1px 1px 1px #282828)
    //        drop-shadow(-1px -1px 1px #282828)
    //        drop-shadow(-1px 1px 1px #282828)
    //        drop-shadow(1px -1px 1px #282828);
}

.slider .swiper-container .slide-inner .link a {
    //filter: drop-shadow(1px 1px 1px #282828)
    //        drop-shadow(-1px -1px 1px #282828)
    //        drop-shadow(-1px 1px 1px #282828)
    //        drop-shadow(1px -1px 1px #282828);
    font-size: 18px !important;
}

.nav-tabs {
    .nav-link {
        h2 {
            opacity: 0.6;
        }
        &.active {
            background-color: transparent !important;
            h2 {
                opacity: 1;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    //bottom: 9px;
                    height: 2px;
                    width: 100%;
                    background: linear-gradient(to right, #4B9BD8, #7158BE) !important;
                }
            }
        }
    }
}

#login-panel-form {
    span.invalid-feedback {
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }
}

.modal {
    background-color: unset !important;
}

.amount-indicator {
    content: " ";
    position: absolute !important;
    color: #dc3545;
    top: -1rem;
    right: -1rem;
    font-size: 2rem;
    font-weight: 700;
    width: 1rem;
    height: 1rem;
}


.pagination {
    margin-top: 30px;
    justify-content: center;

    a  {
        color: $text-color !important;

        &:hover {
            color: $primary !important;
        }
    }

    .page-item.active {
        .page-link {
            background-color: $primary !important;
            border-color: $primary !important;
            color: #ffffff !important;
            opacity: 1 !important;
        }
    }
}

#faq h3 {
    cursor: pointer;
    &:hover {
        font-weight: bold !important;
    }
}
#faq h3::after {
    cursor: pointer;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 20px;
}
#faq h3.collapsed::after {
    content: "\f055";
}
#faq h3::after {
    content: "\f068";
}
.text-red {
    color: $artemis-red-1 !important;
}
.text-blue {
    color: $artemis-blue-1-1 !important;
}
.text-link {
    color: $artemis-blue-2-1 !important;
    text-decoration: underline !important;
    &:hover {
        color: $artemis-blue-1-1 !important;
    }
}

.opacity-10 {
    opacity: 0.1;
}

#faq, #tutorial {
    padding-top: 132px !important;
}

.text-justify {
    text-align: justify !important;
}

img {
    max-width: 100%;
}

@media (max-width: 525px) {
    body {
        font-size: 14px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 17px;
    }
}
@media (max-width: 991px) {
    .navbar .main-menu {
        display: none;
    }
    .form__label {
        font-size: 2.5rem;
    }
    table.dataTable td.text-center, table.dataTable td, table.dataTable th {
        display: list-item;
        width: 100% !important;
        text-align: left !important;
        padding: 0 !important;
    }
    .form__grup.w-33 {
        width: 100% !important;
    }
    .tablo [class^="tablo--"] {
        width: 100% !important;
    }
}
main {
    padding: 0;
}
.footer {
    padding: 0;
}
.tablo {
    padding: 5rem;
    margin-top: 0 !important;
}
.object-fit-contain {
    object-fit: contain !important;
}
.tutorial-video {
    width: 50vw;
    height: calc(50vw / (560 / 315));
    @media (max-width: 991px) {
        width: 75vw;
        height: calc(75vw / (560 / 315));
    }
    @media (max-width: 525px) {
        width: calc(100vw - 20rem);
        height: calc(calc(100vw - 20rem) / (560 / 315));
    }
}
.hizmetler-detay-sayfasi-alani.pb-0 {
    padding-bottom: 0 !important;
}
