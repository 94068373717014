/* style member component */
.list-members {
  background-image: linear-gradient(to right, #000000, #0c0c0c);
  width: 80%;
  margin: 4% auto;
  display: flex;
  flex-wrap: wrap-reverse;
}

.member {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member-image {
  width: 50%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.member-image img {
  width: 100%;
  height: 100%;
  transition: 1s;
}

.member-image:hover img {
  transform: scale(1.1);
}

.member-info {
  width: 50%;
  text-align: center;
}

.member-info p {
  margin: 20px 0;
  text-align: center;
}


/* style social link */
.social-link .fab {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid $beyaz-renk;
  margin: 0 7px;
  cursor: pointer;
  transition: transform .5s;
  background: $beyaz-renk;
  color: #020202;
}

.social-link .fab:hover {
  background: #020202;
  color: $beyaz-renk;
  transform: translateY(-7px);
}

/* Membuat segitiga */
.member-image::after {
  content: '';
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 15px solid  #020202;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}


/* Merubah posisi member-image dengan member-info */
@media screen and (min-width: 771px) {
  .member:nth-child(4n+3) .member-info,
  .member:nth-child(4n+4) .member-info {
    order: 1;
  }
  .member:nth-child(4n+3) .member-image,
  .member:nth-child(4n+4) .member-image {
    order: 2;
  }

  /* Merubah posisi sigitiga pada baris genap */
  .member:nth-child(4n+3) .member-image::after,
  .member:nth-child(4n+4) .member-image::after {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateZ(180deg);
  }
}


/* Mobile Styles */
@media screen and (max-width: 770px) {
  .list-members {
    width: 95%;
  }
  .member {
    flex-basis: 100%;
    font-size: 14px;
  }
  .social-link .fab {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .member:nth-child(even) .member-info {
    order: 1;
  }
  .member:nth-child(even) .member-image {
    order: 2;
  }
  
  /* Merubah posisi sigitiga elemen genap */
  .member:nth-child(even) .member-image::after {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateZ(180deg);
  }
}

p.r1 {
    color: white;
}
h3.r1 {
    color: white;
}