.login-popover, .menu-popover {
    display: none;
}

//.stay-logged-in {
//    display: inline-block;
//    font-size: .9rem;
//    color: grey;
//    margin-left: 8px;
//}

.popover {
    //width: 320px;
    max-width: 480px;
    min-width: 380px;
    font-size: 1rem;
    margin-top: 33px !important;

    .popover-body {
        padding: 0;

        .login-card {
            border: 0 none;

            .input-group {
                .input-group-text {
                    background: #ffffff;

                    i {
                        color: grey;
                    }
                }
            }

            input:not([type=checkbox]) {
                //height: calc(2.5rem + 2px);

                &:focus {
                    box-shadow: none;
                }
            }

            .error {
                color: $red;
            }
        }
    }

    @media (max-width: 991px) {
        display: none!important;
    }

}
