.ui-datepicker {
  display: none;
  padding: .2em .2em 0;
  width: 17em;
  z-index: 100000 !important;
  background: #fff;
  box-shadow: 2px 2px 10px rgba(0,0,0, 0.5);
}

.ui-datepicker .ui-datepicker-header {
  padding: .2em 0;
  position: relative;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  height: 1.8em;
  position: absolute;
  top: 2px;
  width: 1.8em;
  cursor:pointer;

  background: #6D6D6D !important;
  color: #ffffff !important;
  text-decoration: none !important;
  border-radius: 5px !important;
  border: 2px solid #dedede !important;
  display: inline-block !important;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
  font-weight: bold !important;

  &::first-letter
  {
    text-transform: capitalize !important;
  }

  &:hover, &:active, &:focus{
    background: $blue !important;
    color:$white !important;
    text-decoration: none !important;
  }

  span {
    display: block;
    left: 50%;
    margin-left: -8px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    color:#fff;
    overflow:hidden;
  }
}

.ui-datepicker .ui-state-highlight
{
  background: rgba(52, 144,222, 0.5);
}

.ui-datepicker .ui-datepicker-prev { left: 2px; }

.ui-datepicker .ui-datepicker-next { right: 2px; }


.ui-datepicker .ui-datepicker-title {
  line-height: 1.8em;
  margin: 0 2.3em;
  text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}

.ui-datepicker-unselectable
{
  opacity:0.5;
}

.ui-datepicker-week-end
{
  .ui-state-default
  {
    background: #efefef;
  }

}

.ui-datepicker select.ui-datepicker-month-year { width: 100%; }

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year
{
  width: 49%;
  height: calc(2rem + 2px);
  padding: .25rem .5rem;
  font-size: .9rem;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #dedede;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  background-clip: padding-box;
}

.ui-datepicker table {
  border-collapse: collapse;
  font-size: .9em;
  margin: 0 0 .4em;
  width: 100%;
}

.ui-datepicker th {
  border: 0;
  font-weight: bold;
  padding: .7em .3em;
  text-align: center;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
}

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: .7em 0 0 0;
  padding: 0 .2em;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  cursor: pointer;
  float: right;
  margin: .5em .2em .4em;
  overflow: visible;
  padding: .2em .6em .3em .6em;
  width: auto;

  cursor: pointer !important;
  background: #6D6D6D !important;
  color: #ffffff !important;
  padding: 5px 15px !important;
  text-decoration: none !important;
  border-radius: 5px !important;
  border: 2px solid #dedede !important;
  display: inline-block !important;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
  font-weight: bold !important;
  font-size: 16px !important;

  &::first-letter
  {
    text-transform: capitalize !important;
  }

  &:hover, &:active, &:focus{
    background: $blue !important;
    color:$white !important;
    text-decoration: none !important;
  }
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current { float: left; }

/* with multiple calendars */

.ui-datepicker.ui-datepicker-multi { width: auto; }

.ui-datepicker-multi .ui-datepicker-group { float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  margin: 0 auto .4em;
  width: 95%;
}

.ui-datepicker-multi-2 .ui-datepicker-group { width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group { width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group { width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header { border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane { clear: left; }

.ui-datepicker-row-break {
  clear: both;
  font-size: 0em;
  width: 100%;
}

/* RTL support */

.ui-datepicker-rtl { direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  left: auto;
  right: 2px;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  left: auto;
  right: 1px;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane { clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button { float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current { float: right; }

.ui-datepicker-rtl .ui-datepicker-group { float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
  border-left-width: 1px;
  border-right-width: 0;
}

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 1px;
  border-right-width: 0;
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */

.ui-datepicker-cover {
  filter: mask(); /*must have*/
  height: 200px; /*must have*/
  left: -4px; /*must have*/
  position: absolute; /*must have*/
  top: -4px; /*must have*/
  width: 200px; /*must have*/
  z-index: -1; /*must have*/
}


.datepicker_set
{
  min-width: 110px;
  .fa-calendar
  {
    display:inline-block;
    width: 20px;
    height: 20px;
    text-align:center;
    vertical-align:middle;
  }
  .datepicker, .drp
  {
    background: transparent;
    width: calc(100% - 30px);
    margin-right: 5px;
    vertical-align:middle;
  }
  .datetimepicker
  {
    display:inline-block;
    width: calc(100% - 30px);
    margin-right: 5px;
    vertical-align:middle;
  }
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current{
  display:none !important;
}

.ui-datepicker-div{
  position: absolute !important;
}

.datepicker_set {

    &.input-group {

        input {
            &:read-only {
                background-color: $white;
            }
        }

        i {
            font-size: 1.2rem;
            margin-top: 8px;
            margin-left: 8px;
        }
    }

}
