/*-----------------------------------------------------------

  Template Name: Medicare Plastic Surgery Html5 & Css3 Web Site Template
  Template URI: https://www.templatemonster.com/authors/garantiwebt/
  Description: Medicare Plastic Surgery Html5 & Css3 Web Site Template
  Author: adem özdamar
  Author URI: https://www.templatemonster.com/authors/garantiwebt/
  Version: 1.0.2

-------------------------------------------------------------*/

@import "kuramlar/mixin";
@import "temel/araclar";
@import "kuramlar/degiskenler";
@import "temel/temel";
@import "temel/cursor";
@import "kuramlar/fonksiyonlar";
@import "sayfalar/home-sections";

@import "temel/animasyonlar";

@import "temel/tipografi";
@import "bilesenler/swiper";
@import "layout/header";
@import "layout/tablolar";
@import "bilesenler/butonlar";
@import "bilesenler/markalar";
@import "bilesenler/galeri";
@import "bilesenler/hizmetler";
@import "bilesenler/paketler";
@import "bilesenler/yorumlar";
@import "bilesenler/beforeafter";
@import "bilesenler/team";
@import "bilesenler/search";
@import "layout/footer";
@import "layout/menu";
@import "bilesenler/projeler";
@import "bilesenler/form";
@import "bilesenler/social";
@import "bilesenler/slider";
@import "bilesenler/yukari-cik";

@mixin transition-cubic{
	transition-duration: 1s;	-webkit-transition-duration: 1s;
	transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
	-webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1)}

@mixin transition{-webkit-transition: all .35s ease-in-out;
	-moz-transition: all .35s ease-in-out;
	-ms-transition: all .35s ease-in-out;
	-o-transition: all .35s ease-in-out;
	transition: all .35s ease-in-out;}

/* PRELOADER */
.preloader{width: 100%; height: 100%; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; position: fixed; z-index: 99; right: 0; top:0; background: $anarenk-koyu-kirmizi; @include transition-cubic;
	figure{ width: 140px; height: 140px; display: flex; align-items: center; justify-content: center; border: 1px solid rgba(255,255,255,0.2); border-radius: 50%; animation: fadeup 0.30s; position: relative;
		&:after{content: ""; width: 100%; height: 100%; border: 1px solid transparent; border-top: 1px solid #fff; border-radius: 50%; position: absolute; left: 0; top: 0; animation: rotate1 0.60s infinite;}}
	.l img{height: 50px; display: inline-block; ;}}
.page-loaded .preloader{ top:0; transform:translateY(0); opacity: 0; z-index: 0; visibility: hidden;}


@keyframes fadeup {
	0%{transform:translateY(20px); opacity: 0;}
    100%{transform:translateY(0); opacity: 1;}
}
@keyframes rotate1 {
	0%{transform:rotate(0deg); }
    100%{transform:rotate(360deg); }
}

.text-image-teaser-item-content-link:not(.btn) {
    color: $artemis-blue-2-1;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    line-height: 160%;
}
.slider__txt {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.footer__link {
    text-align: left;
}
@media only screen and (max-width: 56.25em) {
    .h2-baslik-anasayfa-ozel {
        font-size: 4rem !important;
    }
}
