*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing:inherit;
}
html{
    font-size: 62.5%;
    scroll-behavior: smooth;

    @include responsive(tablet-buyuk) {
        font-size: 56.25%;
    }

    @include responsive(tablet-kucuk) {
        font-size: 50%;
    }

    @include responsive(telefon) {
        font-size: 30%;
    }


    @include responsive(buyuk-masaustu) {
        font-size: 75%;
    }


}

body{
box-sizing: border-box;
background: #fff;

@include responsive(tablet-kucuk) {
    padding: 0;
}
}

::selection {
    background-color: $anarenk-normal-kirmizi;
    color: $beyaz-renk;
}
::-webkit-scrollbar-thumb {
    background: $anarenk-normal-kirmizi;
}
::-webkit-scrollbar {
    background: $beyaz-renk;
    width: .8rem;
    height: .8rem;
}
