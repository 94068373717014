/* BODY */
* {
  outline: none !important;
}

body {
  margin: 0;
  font-size: 17px;
  color: #282828;
}

/* LINKS */


/* HTML ELEMENTS */
img {
  /*max-width: 100%;*/
}

p {
  line-height: 1.5;
}



/* CUSTOM CONTAINER */
@media (min-width: 1240px) {
  .container {
    max-width: 1200px;
  }
}
/* CUSTOM ELEMENTS */
.color-dark {
  background-color: #282828;
}
.color-dark .left-side ul {
  width: 100vh;
  line-height: 1;
  display: block;
  margin: 0;
  margin-top: 50vh;
  margin-left: -9px;
  padding: 0;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
}
.color-dark .left-side ul li {
  display: inline-block;
  margin: 0 10px;
  padding: 0;
  list-style: none;
}
.color-dark .left-side ul li a {
  display: block;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  padding: 0 2px;
  color: #fff;
}
moe
.color-dark .left-side ul li a:hover {
  text-decoration: none;
}
.color-dark .left-side ul li a:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 6px;
  height: 1px;
  width: 0;
  transition: width 0s ease, background .25s ease;
}
.color-dark .left-side ul li a:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 6px;
  height: 1px;
  width: 0;
  background: #fff;
  transition: width .25s ease;
}
.color-dark .left-side ul li a:hover:before {
  width: 100%;
  background: #fff;
  transition: width .25s ease;
}
.color-dark .left-side ul li a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.color-dark .left-side .logo img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.color-dark .left-side .gotop img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.color-light {
  background-color: #fff;
}

.color-undefined {
  background-color: transparent;
}

.overflow {
  overflow: hidden;
}

/* ODOMETER */
.odometer {
  line-height: 1;
}
.odometer .odometer-digit {
  padding: 0;
}
.odometer .odometer-digit-inner {
  left: 0;
}

/* PAGINATION */
.pagination {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.pagination li.page-item {
  margin-right: 5px;
}
.pagination li.page-item:first-child a.page-link {
  padding-left: 0;
}
.pagination li.page-item.active a.page-link {
  border: 1px solid #282828;
  background: none;
}
.pagination li.page-item a.page-link {
  border: none;
  color: #282828;
  font-size: 13px;
  font-weight: 600;
  border-radius: 50%;
}
.pagination li.page-item a.page-link:hover {
  background: none;
  opacity: 0.5;
}
.pagination li.page-item a.page-link:focus {
  border: none;
  background: none;
  box-shadow: none;
}

/* TRANSITION OVERLAY */
.transition-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: calc(-100% - 200px);
  bottom: 0;
  background: #282828;
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  visibility: hidden;
}
.transition-overlay:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200px 100vh 0;
  border-color: transparent #282828 transparent transparent;
  position: absolute;
  left: -200px;
  top: 0;
}
.transition-overlay.active {
  right: 0;
  visibility: visible;
}

/* PRELOADER */
.preloader {
  top: 0;
  left: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  background: #282828;
  opacity: 1;
  z-index: 99;
  display: none;
}
.preloader * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.preloader:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200px 100vh 0;
  border-color: transparent #282828 transparent transparent;
  position: absolute;
  left: -200px;
  top: 0;
}
.preloader:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100vh 0 0 200px;
  border-color: transparent transparent transparent #282828;
  position: absolute;
  right: -200px;
  top: 0;
}
.preloader .inner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  display: none;
}
.preloader .inner .logo {
  display: inline-block;
  margin: 0;
  animation: fadeInUp ease 0.6s;
}
.preloader .inner .logo img {
  height: 50px;
}
.preloader .inner .percentage {
  width: 100%;
  font-size: 70vh;
  line-height: 1;
  font-weight: 800;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0.05;
  transition-delay: 0.10s;
}

.page-loaded .preloader {

}

.page-loaded .preloader .logo {
  transform: translateX(-100px);
  opacity: 0;
}

.page-loaded .preloader .percentage {
  margin-left: 100px;
  opacity: 0;
  display: none;
}

body .navbar .inner {

}

body .left-side {
  left: -100%;

}

body .header .headlines {
  opacity: 1;
  transform: translateY(100%);

  opacity: 0;
}

body .header .page-title {
  opacity: 1;
  transform: translateY(100%);

  opacity: 0;
}

body .page-header {
  opacity: 1;
  transform: translateY(100%);

  opacity: 0;
}

body .slider {
  transform: translateY(100%);

  opacity: 0;
}

.page-loaded .header .navbar .inner {
  transform: translateY(0);
}

.page-loaded .header .headlines {
  opacity: 1;
  transform: translateY(0);
  opacity: 1;
}

.page-loaded .header .page-title {
  opacity: 1;
  background-image: linear-gradient(to right, #4B9BD8, #7158BE);
  padding-bottom: 50px;
  @include responsive(tablet-buyuk) {
    padding-bottom: 41px;
}
}

.page-loaded .page-header {
  opacity: 1;
  background: #5c7bcb;
  padding-bottom: 53px;
}

.page-loaded .slider {
  opacity: 1;
  transform: translateY(0);
}

.page-loaded .left-side {
  left: 0;
}

.page-loaded .video-wrapper {
  opacity: 1;
  transform: translateY(0);
}

.page-loaded figure.image {
  opacity: 1;
  transform: translateY(0);
}

.page-loaded .header-image {
  opacity: 1;
  transform: translateY(0);
}

.page-loaded .map {
  opacity: 1;
  transform: translateY(0);
}

/* BURGER */
.burger {
  margin-top: 1px;
  z-index: 3;
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.isNavOpen {
  overflow: hidden;
}

.burger-svg__base {
  fill: #fff;
}

.burger-svg__bars {
  fill: #282828;
}

.burger {
  margin-top: 1px;
  z-index: 3;
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.isNavOpen {
  overflow: hidden;
}

.burger-svg__base {
  fill: #fff;
}

.burger-svg__bars {
  fill: #282828;
}

/* NAVIGATION MENU */
.navigation-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: calc(-100% + -200px);
  top: 0;
  z-index: 2;
  background: #282828;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  visibility: hidden;
}
.navigation-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200px 100vh 0;
  border-color: transparent #282828 transparent transparent;
  position: absolute;
  left: -200px;
  top: 0;
}
.navigation-menu.active {
  right: 0;
  visibility: visible;
}
.navigation-menu.active .inner {
  opacity: 1;grid-template: 520px;
  transform: translateX(0);
  /*overflow: scroll;*/
}
.navigation-menu #map {
  width: 600px;
  height: 450px;
  display: none;
  border: none;
}
.navigation-menu .inner {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0 10%;
  color: #fff;
  transform: translateX(40%);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 0.35s;
}
.navigation-menu .inner .side-menu {
  width: 100%;
  float: right;
  display: none;
}
.navigation-menu .inner .side-menu ul {
  float: right;
  margin: 0;
  padding: 0;
  text-align: right;

  @include responsive(tablet-buyuk) {
    float: right;
    margin: 0;
    padding: 0;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 123px;
}



}

.navigation-menu .inner .side-menu ul li {
  display: block;
  list-style: none;
  margin-bottom: 5px;
}
.navigation-menu .inner .side-menu ul li a, .navigation-menu .inner .side-menu ul li button {
  font-size: 8vw;
  color: #fff;
  font-weight: 600;
  @media screen and (min-width: 300px) and (max-width: 600px) {
    font-size: 8vw;
    color: #fff;
    font-weight: 600;
    margin-right: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 1.3;
}
@media screen and (min-width: 600px) and (max-width: 700px) {
  font-size: 4vw;
  color: #fff;
  font-weight: 600;
  margin-right: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 1.3;
}

@media screen and (min-width: 701px) and (max-width: 1250px) {
  font-size: 3vw;
  color: #fff;
  font-weight: 600;
  margin-right: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 1.3;
}

}
.navigation-menu .inner .sides {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.navigation-menu .inner .sides:last-child {
  padding-left: 50px;
}
.navigation-menu .inner h2 {
  display: block;
  margin-bottom: 20px;
  line-height: 1.7;
  font-size: 25px;
}
.navigation-menu .inner h2 strong {
  font-weight: 600;
}
.navigation-menu .inner figure {
  display: block;
  margin: 0;
  background: #fff;
  padding: 6px;
  position: relative;
}
.navigation-menu .inner figure a {
  width: 50px;
  height: 50px;
  line-height: 44px;
  position: absolute;
  right: 6px;
  top: 6px;
  background: #fff;
  text-align: center;
}
.navigation-menu .inner address {
  display: block;
  font-size: 19px;
}
.navigation-menu .inner address a {
  display: block;
  color: #fff;
  text-decoration: underline;
}

/* REVEAL EFFECT */
.reveal-effect {
  float: left;
  overflow: hidden;
  position: relative;
}
.reveal-effect.animated:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #eee;
  position: absolute;
  left: 0;
  top: 0;
  animation: 1s reveal linear forwards;
  -webkit-animation-duration: 1s;
  z-index: 1;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.reveal-effect.animated > * {
  animation: 1s reveal-inner linear forwards;
}

@keyframes reveal {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
  }
  51% {
    left: auto;
    right: 0;
  }
  100% {
    left: auto;
    right: 0;
    width: 0;
  }
}
@keyframes reveal-inner {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  50% {
    visibility: hidden;
    opacity: 0;
  }
  51% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.titles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-right: 50%;
  margin-bottom: 80px;
}
.titles h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 2px;
}
.titles p {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 300;
}

/* CUSTOM LINK */
.custom-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.custom-link * {
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}
.custom-link a {
  display: inline-block;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.custom-link a:hover {
  text-decoration: none;
}
.custom-link span {
  width: 20px;
  height: 1px;
  background: #282828;
  display: inline-block;
  margin: 0;
}
.custom-link i {
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #282828;
  opacity: 1;
}
.custom-link:hover a {
  margin-left: 20px;
}
.custom-link:hover span {
  width: 50px;
  margin-left: 20px;
}
.custom-link:hover i {
  opacity: 0;
}

/* CUSTOM BUTTON */
.custom-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
}
.custom-btn * {
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}
.custom-btn a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 58px;
  border: 1px solid #282828;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 30px;
  padding-top: 18px;
  background: #282828;
  color: #fff;
}
.custom-btn a:hover {
  text-decoration: none;
}
.custom-btn span {
  width: 20px;
  height: 1px;
  background: #fff;
  display: inline-block;
  margin-left: 20px;
  margin-top: 8px;
}
.custom-btn i {
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #fff;
  opacity: 1;
  margin-top: 5px;
}
.custom-btn:hover span {
  width: 50px;
  margin-left: 20px;
}
.custom-btn:hover i {
  opacity: 0;
}



/* NAVBAR */
.navbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  z-index: 9;
  background: white;
  padding: 25px;

}
.navbar.hide {

}
.navbar.light {
  color: #fff;
}
.navbar.light .logo a img {

}
.navbar.light .main-menu ul li a, .navbar.light .main-menu ul li button {
  color: #fff;
}
.navbar.light .main-menu ul li a:after, .navbar.light .main-menu ul li button:after {
  background: #fff;
}
.navbar.light .main-menu ul li a:hover:before, .navbar.light .main-menu ul li button:hover:before,
.navbar.light .main-menu ul li a.active:before, .navbar.light .main-menu ul li button.active:before {
  background: #fff;
}
.navbar .inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar .logo {
  margin-left: 0;
}
.navbar .logo a {
  margin: 0;
}
.navbar .logo a img {
  height: 62px;
}
.navbar .phone {
  margin-left: 50px;
  font-size: 20px;
  font-weight: 700;
}
.navbar .main-menu {
  margin-left: auto;
}
.navbar .main-menu ul {
  margin: 0;
  padding: 0;
}
.navbar .main-menu ul li {
  display: inline-block;
  margin: 0 18px 0 auto;
  padding: 0;
}
.navbar .main-menu ul li a, .navbar .main-menu ul li button {
  display: block;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  padding: 0 2px;
  background-image: linear-gradient(to right, #4B9BD8, #7158BE);
  -webkit-background-clip: text;
  color: transparent;
}
.navbar .main-menu ul li a:hover, .navbar .main-menu ul li button:hover,
.navbar .main-menu ul li a.active, .navbar .main-menu ul li button.active {
  text-decoration: none;
  background-image: linear-gradient(to right, #2f73a7, #403072);
    -webkit-background-clip: text;
    color: transparent;
}
.navbar .main-menu ul li a:before, .navbar .main-menu ul li button:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 7px;
  height: 1px;
  width: 0;
  transition: width 0s ease, background .25s ease;
}
.navbar .main-menu ul li a:after, .navbar .main-menu ul li button:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 9px;
  height: 1px;
  width: 0;
  background: #282828;
  transition: width .25s ease;
}
.navbar .main-menu ul li a:hover:before, .navbar .main-menu ul li button:hover:before,
.navbar .main-menu ul li a.active:before, .navbar .main-menu ul li button.active:before{
  width: 100%;
  background: linear-gradient(to right, #4B9BD8, #7158BE);
  transition: width .25s ease;
}
.navbar .main-menu ul li a:hover:after, .navbar .main-menu ul li button:hover:after,
.navbar .main-menu ul li a.active:after, .navbar .main-menu ul li button.active:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.navbar .hamburger-menu {
  margin-right: 0;
  margin-left: 30px;
}

/* LEFT SIDE */
.left-side {
  left: 0;
  top: 0;
}
.left-side .logo {
  width: 100%;
  display: block;
  height: 42px;
  text-align: center;
  margin-top: 35px;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  opacity: 0;
  transform: translateY(-30px);
  display: none;
}
.left-side .logo img {
  height: 62px;
}
.left-side .logo.sticky {
  opacity: 1;
  transform: translateY(0);
}
.left-side ul {
  width: 100vh;
  line-height: 1;
  display: block;
  margin: 0;
  margin-top: 50vh;
  margin-left: -9px;
  padding: 0;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
}
.left-side ul li {
  display: inline-block;
  margin: 0 10px;
  padding: 0;
  list-style: none;
}
.left-side ul li a {
  display: block;
  font-size: 17px;
  font-weight: 800;
  position: relative;
  padding: 0 2px;
}
.left-side ul li a:hover {
  text-decoration: none;
}
.left-side ul li a:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 1px;
  width: 0;
  transition: width 0s ease, background .25s ease;
}
.left-side ul li a:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0px;
  height: 1px;
  width: 0;
  background: #282828;
  transition: width .25s ease;
}
.left-side ul li a:hover:before {
  width: 100%;
  background: #282828;
  transition: width .25s ease;
}
.left-side ul li a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.left-side .gotop {
  width: 64px;
  padding: 14px;
  position: absolute;
  left: 39px;
  bottom: 35px;
  display: none;
}
.left-side .gotop img {
  display: block;
}




/* LOGOS */
.logos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 76px 0;
  background-image: linear-gradient(to right, #52c8e9, #7158BE);
}
.logos ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.438);
  border-top: 1px solid rgba(255, 255, 255, 0.438);
}
.logos ul li {
  width: 20%;
  float: left;
  list-style: none;
  border-right: 1px solid rgba(255, 255, 255, 0.438);
  border-bottom: 1px solid rgba(255, 255, 255, 0.438);
  text-align: center;
}
.logos ul li:hover figure {
  opacity: 1;
}
.logos ul li figure {
  display: block;
  height: 118px;
  margin: 30px 0;
  opacity: 0.8;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  position: relative;
}
.logos ul li figure img {
  display: none;
  height: 132px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

/* WORK WITH US */
.work-with-us {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  color: #fff;
}
.work-with-us h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
}
.work-with-us h2 {
  font-size: 6vw;
  font-weight: 800;
  margin-bottom: 50px;
}
.work-with-us p {
  display: block;
  margin: 0;
  font-size: 19px;
}
.work-with-us a {
  color: #fff;
  text-decoration: underline;
}
.work-with-us small {
  display: block;
  margin: 15px 0;
}



/* ANIMATIONS */
@keyframes countdown {
  from {
    stroke-dashoffset: 113px;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 2000px) {
  .navbar .hamburger-menu {
    display: none;
   }
}


/* MEDIUM FIXES */
@media only screen and (max-width: 1199px), only screen and (max-device-width: 1199px) {
  body {
    padding: 0;
  }

  .left-side {
    display: none;
  }

  .navbar {
    padding: 30px;
  }

  .slider {
    width: 100%;
    margin-right: 0;
  }

  .slider .swiper-container .slide-inner {
    padding: 0 60px;
  }

  .works .titles {
    padding-right: 0;
  }
}
/* RESPONSIVE TABLET FIXES */
@media only screen and (max-width: 991px), only screen and (max-device-width: 1250px) {
  .slider .swiper-container .slide-inner {
    padding: 0 30px;
  }

  .preloader .inner .percentage {
    font-size: 50vh;
  }

  .navigation-menu .inner .side-menu {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  //.navbar .main-menu {
  //  display: none;
  //}

  .navbar .phone {
    margin-right: auto;
  }

  .navigation-menu .inner .sides {
    display: none;
  }

  .slider .swiper-container .swiper-slide {
    width: 100%;
  }

  .slider:hover .swiper-container .swiper-slide {
    margin-left: 0;
  }

  .header .page-title h4 {
    padding-right: 0;
  }

  .titles {
    padding-right: 0;
  }

  .works ul li {
    width: 50%;
  }

  .our-awards ul li {
    width: 33%;
    float: none;
    display: inline-block;
    margin: 0 6%;
  }

  .our-awards ul li figure {
    border-right: none;
  }

  .our-awards ul li .odometer {
    margin-bottom: 40px;
  }

  .team figure {
    margin-bottom: 40px;
  }

  .logos ul li {
    width: 33.33333%;
  }

  .logos ul li:nth-child(n+10) {
    display: none;
  }

  .our-awards .titles {
    padding: 0 5%;
  }

  .post-sticky .post-content {
    padding-right: 0;
  }

  .post-sticky .post-content .post-intro {
    display: none;
  }

  .blog {
    padding-top: 100px;
  }

  .blog .sidebar {
    width: 100%;
    margin-top: 100px;
  }

  .swiper-button-next {
    display: none;
  }

  .footer .logo {
    margin-bottom: 40px;
  }

  .footer .career h2 {
    font-size: 6vw;
  }


  textarea {
    border-radius: 0 !important;
    -webkit-appearance: none;
  }
}
/* RESPONSIVE MOBILE FIXES */
@media only screen and (max-width: 767px), only screen and (max-device-width: 767px) {
  .preloader .inner .percentage {
    font-size: 30vh;
  }

  .navbar {
    padding: 30px 15px;
  }

  .navbar .phone {
    display: none;
  }

  .slider .swiper-container .slide-inner {
    padding: 0 30px;
  }

  .slider .swiper-container .slide-inner h2 {
    font-size: 35px;
  }

  .recent-news .row {
    margin-left: -15px;
    margin-right: -15px;
  }

  .recent-news .col-lg-4 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .recent-news .col-12 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header .page-title h2 {
    font-size: 10vw;
  }

  .works ul li {
    width: 100%;
  }

  .works ul {
    width: 100%;
    margin: 0 !important;
  }

  .our-awards .titles {
    padding: 0;
  }

  .logos ul li {
    width: 50%;
  }

  .logos ul li:nth-child(9) {
    display: none;
  }

  .post-sticky .post-content {
    padding: 20px 30px;
  }

  .post-sticky .post-content .post-link {
    display: none;
  }

  .blog .post .post-content .post-title {
    font-size: 6vw;
  }

  .post-body .post-title {
    font-size: 6vw;
  }

  .post-navigation a:nth-child(1) {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #eee;
  }

  .post-navigation a:nth-child(2) {
    width: 100%;
  }

  .work-with-us h2 {
    font-size: 9vw;
  }

  .logos ul li figure {
    height: 65px;
  }

  .logos ul li figure img {
    height: 65px;
  }

  .about-intro h2 {
    font-size: 8vw;
  }

  .main-services .titles {
    padding: 0;
  }

  .footer .career h2 {
    font-size: 9vw;
  }

  .footer .sub-footer ul {
    margin-bottom: 15px;
  }
}

span.numberp {
  background-image: linear-gradient(to right, #4B9BD8, #7158BE);
  -webkit-background-clip: text;
  color: transparent;

}

