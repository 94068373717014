/* Efektler */

@keyframes solahareket {
    0% {
     opacity: 0;
     transform: translateX(-10rem);
    }
    80%{
     transform: translateX(1rem);
    }
    100%{
     opacity: 1;
     transform: translate(0);
    }
}

@keyframes sagahareket {
    0%{
    opacity: 0;
    transform: translateX(10rem);
    }
    80%{
    transform: translateX(-1rem);
    }
    100%{
    opacity: 1;
    transform: translate(0);
    }
}

@keyframes butonefekt {
    0%{
     opacity: 0;
     transform: translateY(3rem);
    }
    100%{
    opacity: 1;
    transform: translate(0);
    }
}

// Slider animasyon


.jssorl-009-spin img {
    animation-name: jssorl-009-spin;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes jssorl-009-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


/*jssor slider bullet skin 132 css*/
.jssorb132 {position:absolute;}
.jssorb132 .i {position:absolute;cursor:pointer;}
.jssorb132 .i .b {fill:#fff;fill-opacity:0.8;stroke:$anarenk-normal-pembe;stroke-width:1600;stroke-miterlimit:10;stroke-opacity:0.7;}
.jssorb132 .i:hover .b {fill:$anarenk-normal-pembe;fill-opacity:.7;stroke:#fff;stroke-width:2000;stroke-opacity:0.8;}
.jssorb132 .iav .b {fill:$anarenk-normal-pembe;stroke:#fff;stroke-width:2400;fill-opacity:0.8;stroke-opacity:1;}
.jssorb132 .i.idn {opacity:0.3;}

.jssora051 {display:block;position:absolute;cursor:pointer;}
.jssora051 .a {fill:none;stroke:#fff;stroke-width:360;stroke-miterlimit:10;}
.jssora051:hover {opacity:.8;}
.jssora051.jssora051dn {opacity:.5;}
.jssora051.jssora051ds {opacity:.3;pointer-events:none;}

/* 
Animasyonlar
*/
