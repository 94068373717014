/* SLIDER */
.slider {
	width: calc(100% + 50px);
	height: 560px;
	display: flex;
	flex-wrap: wrap;
	background: #ffffff;
	margin-right: -50px;
	overflow: hidden;
	position: relative;
  }
  .slider:hover .swiper-container .swiper-slide {
  }
  .slider:hover .swiper-button-next span {
	width: 100%;
  }
  .slider .swiper-container {
	width: 100%;
	height: 100%;
	position: relative;
  }
  .slider .swiper-container .swiper-slide {
	width: calc(100% - 80px);
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-ms-transition: all .25s ease-in-out;
	-o-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
	overflow: hidden;
	background-size: cover;
	background-position: center;
  }
  .slider .swiper-container .swiper-slide-active {
	opacity: 1;
	z-index: 2;
	width: 100%;
  }
  .slider .swiper-container .swiper-slide-active .slide-inner {
	opacity: 1;
  }
  .slider .swiper-container .slide-inner {
	width: 100%;
	padding: 0 100px;
	padding-right: 50%;
	opacity: 0;
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-ms-transition: all .25s ease-in-out;
	-o-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
  }
  .slider .swiper-container .slide-inner figure {
	display: block;
	margin-bottom: 10px;
  }
  .slider .swiper-container .slide-inner figure img {
	max-width: 120px;
  }
  .slider .swiper-container .slide-inner h2 {
	font-size: 30px;
	font-weight: 600;
	background-image: linear-gradient(to right, #4B9BD8, #7158BE);
    -webkit-background-clip: text;
    color: transparent;
	margin-left: 15px;
	@include responsive(tablet-kucuk) {
		font-size: 20px;
		margin-top: 91px;
	} 
  }
  .slider .swiper-container .slide-inner .link {
	display: block;
	margin-top: 50px;
  }
  .slider .swiper-container .slide-inner .link a {
	display: inline-block;
	font-weight: 600;
	background-image: linear-gradient(to right, #4B9BD8, #7158BE);
    -webkit-background-clip: text;
    color: transparent;
	font-size: 13px;
	position: relative;
	margin-left: 15px;
  }
  .slider .swiper-container .slide-inner .link a:before {
	content: "";
	width: 100%;
	height: 6px;
	background: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
	opacity: 0.3;
  }
  .slider .swiper-container .slide-inner .link a:after {
	content: "";
	width: 0;
	height: 6px;
	background: #fff;
	position: absolute;
	right: 0;
	bottom: 0;
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-ms-transition: all .25s ease-in-out;
	-o-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
  }
  .slider .swiper-container .slide-inner .link a:hover {
	text-decoration: none;
  }
  .slider .swiper-container .slide-inner .link a:hover:after {
	width: 100%;
  }
  
  /* SWIPER BUTTONS */
  .swiper-button-next {
	width: 80px;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	left: auto;
	right: 20px;
	top: 0;
	z-index: 2;
	background: none;
	text-align: right;
	display: none;
  }
  .swiper-button-next span {
	width: 50%;
	height: 1px;
	margin-left: auto;
	background: #7158be;
	display: inline-block;
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-ms-transition: all .25s ease-in-out;
	-o-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
  }
  .swiper-button-next b {
	font-weight: 600;
	display: block;
	font-size: 12px;
	background-image: linear-gradient(to right, #4B9BD8, #7158BE);
    -webkit-background-clip: text;
    color: transparent;
	margin-top: 5px;
  }
  
  /* SWIPER PAGINATION */
  .swiper-pagination-bullet {
	opacity: 1;
	background: rgba(0, 0, 0, 0.2);
	position: relative;
	height: 40px;
	width: 40px;
	background: none;
	text-align: center;
	transform: scaleX(-1) scale(0.7);
	margin: 0;
  }
  .swiper-pagination-bullet:before {
	content: "";
	width: 100%;
	height: 100%;
	border: 3px solid #745bc0;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
	
  }
  
  .swiper-pagination-bullet-active {
	color: #fff;
	background: none;
  }
  .swiper-pagination-bullet-active:after {
	content: "";
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #8873c9;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -7px;
	margin-top: -7px;
  }
  
  .swiper-pagination-bullet svg {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	transform: rotateY(-180deg) rotateZ(-90deg);
  }
  
  .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
	animation-play-state: running;
	stroke-width: 3px;
	animation: countdown 6s linear forwards;
  }
  
  .swiper-pagination-bullet svg circle {
	stroke-dasharray: 113px;
	stroke-dashoffset: 0px;
	stroke-linecap: round;
	stroke-width: 0;
	stroke: #4b9bd8;
	fill: none;
	animation: none;
	animation-play-state: paused;
	animation-fill-mode: none;
  }
  
  .slider {
	  position: relative;
	  width: 100%;
	  min-height: 97vh;
	  display: flex;
	  justify-content: center;
	  align-items: flex-end;
  }
  .slider input[type="radio"] {
	  position: relative;
	  z-index: 888;
	  margin: 5px;
	  margin-bottom: 40px;
	  outline: none;
	  cursor: pointer;
  }
  

