@import "icon-font";
@import "style";
@import "animate.min";
@import "fancybox.min";
@import "odometer.min";
@import "BeerSlider";
@import "BeerSlider.unmin";

.slider {
    min-height: unset;
    height: auto;
    .swiper-container {
        .swiper-slide {
            background-size: 100% auto !important;
            background-repeat: no-repeat;
            background-position: left center;
            width: 100%;
        }
    }
}

@media (max-width: 56.25em) {
    .hm-services2 {
        padding: 0 !important;
    }
    .tabloservices {
        padding: 5rem !important;
    }
    .tabloservices [class^="tablo--"] {
        display: flex;
    }
    .services-kutu {
        width: 100%;
        margin: 0 5rem;
    }
    .bosluk8 {
        margin-top: 5rem !important;
    }
    .bosluk3 {
        margin-top: 0rem !important;
    }
    .hm-services2 {
        margin-top: 0;
    }
    .hakkimizda-bolumu-anasayfa1 {
        margin-top: 0 !important;
    }
     .bosluk4 {
         margin-top: 0;
     }
    .footer {
        font-size: unset;
        padding: 5rem 0;
    }
    //.tablo {
    //    padding: 10rem 5rem !important;
    //}
    .tablohizmetlerdetay {
        margin: 0 !important;
        padding: 5rem !important;
    }
    .amount-indicator i {
        font-size: 5rem;
    }
}
.yorumlar-alani-sayfa {
    padding: 5rem 0 !important;
}

.hizmetler-detay-sayfasi-alani {
    padding: 6rem 0 !important;
}

@media only screen and (min-width: 992px) {
    .navbar .hamburger-menu {
        display: none !important;
    }
    .navbar.light .main-menu ul li a, .navbar.light .main-menu ul li button {
        color: transparent !important;
    }
    .navigation-menu.active {
        visibility: hidden !important;
    }
}
.navigation-menu .inner .side-menu ul li a, .navigation-menu .inner .side-menu ul li button {
    text-align: center;
}
@media screen and (min-width: 600px) {
    .navigation-menu .inner .side-menu ul li a, .navigation-menu .inner .side-menu ul li button {
        font-size: 6vw !important;
    }
}
.navigation-menu .inner .side-menu ul li {
    margin-bottom: 5rem !important;
}
.navigation-menu.active {
    right: 0;
    visibility: visible;
    width: 100vw;
    height: 100vh;
    margin: 0 !important;
    left: 0;
}
.header {
    position: relative;
    .navbar {
        max-width: 100vw;
    }
}
.hm-services2 {
    margin-top: -10rem;
}
.baslik-3-1 {
    color: $primary;
}
.h2-baslik-anasayfa-ozel,
.h2-baslik-footer,
.h2-baslik-hizmetler-yorum,
.navbar .main-menu ul li a, .navbar .main-menu ul li button,
.footer__link, .footer__sosyallink, .footerss p, .footer__copyright
{
    background-image: linear-gradient(to right, $artemis-blue-1-1, $artemis-blue-1-2) !important;
}
.buton--kirmizi {
     background-color: $primary !important;
 }
.buton--kirmizi::after {
    background-color: $artemis-blue-3-1 !important;
}
.our-awards {
    background-image: linear-gradient(to right, $artemis-blue-1-2, $artemis-blue-1-1) !important;
}
.footer__copyright {
    border-top: 0.1rem solid $artemis-blue-1-1 !important;
}
.preloader {
    background: $artemis-blue-1-2 !important;
}
.slider__nav {
    border-color: $artemis-blue-1-1 !important;
}
.slider__nav:checked {
    background-color: $artemis-blue-1-1 !important;
}
.page-teaser {
    display: none;
}
.page-loaded .page-teaser {
    display: block;
}
.swiper-pagination-bullet::before {
    border: 3px solid $artemis-blue-2-1 !important;
}
.swiper-pagination-bullet-active::after {
    background: $artemis-blue-2-1 !important;
}
.services-kutu1 {
    background: $artemis-blue-1-1 !important;
}

