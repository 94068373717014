.hizmetler-kutu {
    background-color: $beyaz-renk;
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 5.5rem 5.5rem;
    box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);
    transition: transform .3s;

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        margin-bottom: .5rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-mavi,$anarenk-koyu-mavi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
    }

    &--yazi {
        text-align: center;

        @include responsive(tablet-kucuk) {
            font-size: 3.5rem;
        }
    }

}

// özellik sayfası için 1

.ozellik-kutu {
    /*background-color: $beyaz-renk;*/
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    /*box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);*/
    transition: transform .3s;
    /*height: 330px;*/

    @media only screen and (max-width: 1180px) {
        text-align: center;
        align-items: center;
        clear:both;
        margin: auto;
    }

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-kirmizi,$anarenk-koyu-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        /*transform: translateY(-1.5rem) scale(1.03);*/
    }

    &--yazi {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;

        @media only screen and (max-width: 375px) {
            font-size: 3.3rem;
            width: 290px;
        }

        @include responsive(tablet-kucuk) {
            font-size: 3.3rem;

        }


    }

}

img.haber-gorsel {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border: 0.7rem solid transparent;
    border-image: linear-gradient(to right, #4c99d7, #6f5cbf);
    border-image-slice: 1;

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
    @media only screen and (max-width: 1180px) {
        width: 100%;
    }

}

img.team-gorsel {

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }

}

// özellik sayfası 2

.ozellik-kutu-siyah {
    background-color: $siyah-renk-1;
    color: $beyaz-renk;
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: .3rem;
    box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);
    transition: transform .3s;

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        margin-bottom: .5rem;
        display: inline-block;
        background-image: linear-gradient(to right, #ffffff, #cecece);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
    }

    &--yazi {
        text-align: center;

        @include responsive(tablet-kucuk) {
            font-size: 2.3rem;
        }
    }

}
// özellik sayfası beyaz

.ozellik-kutu-beyaz {
    background-color: $siyah-renk-1;
    color: $beyaz-renk;
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: .3rem;
    box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);
    transition: transform .3s;

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        margin-bottom: .5rem;
        display: inline-block;
        background-image: linear-gradient(to right, #ffffff, #cecece);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
    }

    &--yazi {
        text-align: center;
        color: $beyaz-renk;

        @include responsive(tablet-kucuk) {
            font-size: 3rem;
        }
    }

}
// Post Kutu

.post-kutu {
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    transition: transform .3s;
    border-radius: 5rem;
    -moz-border-radius: 50px;
    -webkit-border-radius: 500px;
    width: 450px;
    
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media only screen and (max-width: 1024px) {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    @include responsive(telefon) {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    @include responsive(tablet-kucuk) {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

    }
    @media only screen and (max-width: 1180px) {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }


    &--icon {
    
        font-size: 6rem;
        margin-bottom: -0.5rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-kirmizi,$anarenk-koyu-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
    }

    &--yazi {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 1rem;

        @include responsive(tablet-kucuk) {
            font-size: 3.5rem;
            text-align: center;
            padding: 2rem;
        }
    }

}

// yorum kutu

.yorum-kutu {
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    /*transition: transform .3s;*/
    border-radius: 5rem;
    -moz-border-radius: 50px;
    -webkit-border-radius: 500px;
    width: 450px;
    height: 453px;

    @media only screen and (max-width: 1024px) {
        width: auto;
        height: auto;
    }

    @include responsive(telefon) {
        padding: 2rem;
        width: auto;
        height: auto;
        text-align: left;
    }

    @include responsive(tablet-kucuk) {
        padding: 2rem;
        width: auto;
        height: auto;
        text-align: left;

    }

    &--icon {
    
        font-size: 6rem;
        margin-bottom: -0.5rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-kirmizi,$anarenk-koyu-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
       /* transform: translateY(-1.5rem) scale(1.03);*/
    }

    &--yazi {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 1rem;

        @include responsive(tablet-kucuk) {
            font-size: 3.5rem;
            text-align: left;
            padding: 2rem;
        }
    }

}
// iletişim özellik icon

.ozellik-kutu-iletisim {
    background-color: $beyaz-renk;
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: .3rem;
    box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);
    /*transition: transform .3s;*/

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        margin-bottom: -2rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-koyu-kirmizi,$anarenk-normal-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        /*transform: translateY(-1.5rem) scale(1.03);*/
    }

    &--yazi {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        color: $gri-renk-7;
    

        @include responsive(tablet-kucuk) {
            font-size: 3.5rem;
        }
    }

    &--yazi-iletisim {
        text-align: center;
        font-size: 1.6rem;
        font-weight: 400;
        color: $anarenk-normal-kirmizi;

        @include responsive(tablet-kucuk) {
            font-size: 3.5rem;
        }
    }

}

.baslik-4 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 600;
  background-image: linear-gradient(to right, #4B9BD8, #7158BE);
  -webkit-background-clip: text;
  color: transparent;

    @media only screen and (max-width: 1024px) {
        width: auto;
    }

    @include responsive(tablet-kucuk) {
        padding: 2rem;
        font-size: 5rem;
    }

    &--icon {
    
        font-size: 6rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-kirmizi,$anarenk-koyu-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        /*transform: translateY(-1.5rem) scale(1.03);*/
    }

    &--yazi {
        text-align: center;
        font-size: 1.3rem;
        font-weight: 400;

        @media only screen and (max-width: 375px) {
            font-size: 3.1rem;
            width: 290px;
        }

        @include responsive(tablet-kucuk) {
            font-size: 3.3rem;

        }


    }

}

// yorumlar için
.ozellik-kutu-yorumlar {
    background-color: $beyaz-renk;
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);
    transition: transform .3s;
    width: 385px;
    /*height: 330px;*/

    @media only screen and (max-width: 1024px) {
        width: auto;
    }

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-kirmizi,$anarenk-koyu-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        /*transform: translateY(-1.5rem) scale(1.03);*/
    }

    &--yazi {
        text-align: center;
        font-size: 1.3rem;
        font-weight: 400;

        @media only screen and (max-width: 375px) {
            font-size: 3.1rem;
            width: 290px;
        }

        @include responsive(tablet-kucuk) {
            font-size: 3.3rem;

        }


    }

}

// services 1
.services-kutu1 {
    /*background-color: $beyaz-renk;*/
    padding: 2.5rem;
    font-size: 1.5rem;
    /*box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);*/
    transition: transform .3s;
    text-align: center;
    background: #764fbb;
    border-radius: 5px;
    /*height: 330px;*/

    @media only screen and (max-width: 1180px) {
        text-align: center;
        align-items: center;
        clear:both;
        margin: auto;
    }

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-kirmizi,$anarenk-koyu-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        /*transform: translateY(-1.5rem) scale(1.03);*/
    }

    &--yazi {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;

        @media only screen and (max-width: 375px) {
            font-size: 3.3rem;
            width: 290px;
            
        }

        @include responsive(tablet-kucuk) {
            font-size: 3.3rem;
            text-align: center;

        }
      }   
    &--yazi1 {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      color: $beyaz-renk;

      @media only screen and (max-width: 375px) {
          font-size: 3.3rem;
          width: 290px;
          
      }

      @include responsive(tablet-kucuk) {
          font-size: 3.3rem;
          text-align: center;

      }
    }
    &--yazi2 {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;

      @media only screen and (max-width: 375px) {
          font-size: 3.3rem;
          width: 290px;
          
      }

      @include responsive(tablet-kucuk) {
          font-size: 3.3rem;
          text-align: center;

      }
    }
    &--yazi3 {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      color: $beyaz-renk;

      @media only screen and (max-width: 375px) {
          font-size: 3.3rem;
          width: 290px;
          
      }

      @include responsive(tablet-kucuk) {
          font-size: 3.3rem;
          text-align: center;

      }
    }         

}

// services 2
.services-kutu2 {
    /*background-color: $beyaz-renk;*/
    padding: 2.5rem;
    text-align: left;
    font-size: 1.5rem;
    /*box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);*/
    transition: transform .3s;
    text-align: center;
    background: #f9f9f9;
    border-radius: 5px;
    /*height: 330px;*/

    @media only screen and (max-width: 1180px) {
        text-align: center;
        align-items: center;
        clear:both;
        margin: auto;
    }

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-kirmizi,$anarenk-koyu-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        /*transform: translateY(-1.5rem) scale(1.03);*/
    }

    &--yazi {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;

        @media only screen and (max-width: 375px) {
            font-size: 3.3rem;
            width: 290px;
        }

        @include responsive(tablet-kucuk) {
            font-size: 3.3rem;

        }


    }

}

// services 3
.services-kutu3 {
    /*background-color: $beyaz-renk;*/
    padding: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    /*box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);*/
    transition: transform .3s;
    background: #4f9dd9;
    border-radius: 5px;
    /*height: 330px;*/

    @media only screen and (max-width: 1180px) {
        text-align: center;
        align-items: center;
        clear:both;
        margin: auto;
    }

    @include responsive(tablet-kucuk) {
        padding: 2rem;
    }

    &--icon {
    
        font-size: 6rem;
        display: inline-block;
        background-image: linear-gradient(to right, $anarenk-acik-kirmizi,$anarenk-koyu-kirmizi);
        -webkit-background-clip: text;
        color: transparent;

        @include responsive(tablet-kucuk) {
            margin-bottom: 0;
            font-size: 13rem;
        }
        
    }

    &:hover {
        /*transform: translateY(-1.5rem) scale(1.03);*/
    }

    &--yazi {
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;

        @media only screen and (max-width: 375px) {
            font-size: 3.3rem;
            width: 290px;
        }

        @include responsive(tablet-kucuk) {
            font-size: 3.3rem;

        }


    }

}

/* PAGE HEADER */
.page-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #282828;
  }
  .page-header .video-bg {
    width: 100%;
  }
  .page-header .video-bg video {
    width: 100%;
    display: flex;
  }
  .page-header iframe {
    width: 100%;
    height: 500px;
    display: block;
    border: none;
  }
  .page-header figure {
    width: 100%;
    margin: 0;
  }
  .page-header figure img {
    width: 100%;
  }
  
  /* MAIN SERVICES */
  .main-services {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
    text-align: center;
  }
  .main-services .titles {
    padding: 0 20%;
    color: #fff;
  }
  .main-services figure {
    display: inline-block;
    margin-bottom: 0;
  }
  .main-services figure img {
    height: 60px;
    display: inline-block;
  }
  .main-services article {
    width: 100%;
    float: left;
    margin-bottom: 50px;
    color: #fff;
    position: relative;
    padding: 0 30px;
  }
  .main-services article span {
    display: block;
    font-weight: 700;
    font-size: 40px;
    opacity: 0.1;
  }
  .main-services article h4 {
    display: block;
    margin-top: -25px;
    font-weight: 600;
    font-size: 18px;
  }
  
  /* WORKS */
  .works {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 80px 0;
  }
  .works .titles {
    display: block;
    padding-right: 15%;
    margin-bottom: 20px;
  }
  .works .titles h2 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .works .titles p {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 40px;
    font-weight: 300;
  }
  .works ul {
    margin-bottom: 0;
    margin-left: -30px;
    margin-right: -30px;
    padding: 0;
  }
  .works ul li {
    width: 50%;
    margin: 40px 0;
    padding: 0 30px;
    list-style: none;
  }
  
  /* PROJECT BOX */
  .project-box {
    width: 100%;
    display: block;
  }
  .project-box .project-image {
    width: 100%;
    display: block;
  }
  .project-box .project-image a {
    display: block;
  }
  .project-box .project-image a
  img {
    width: 100%;
  }
  .project-box .project-content {
    width: 100%;
    display: block;
  }
  .project-box .project-content h3 {
    font-size: 27px;
    display: block;
  }
  .project-box .project-content h3 a {
    display: block;
  }
  .project-box .project-content h3 a:hover {
    text-decoration: none;
  }
  .project-box .project-content small {
    display: block;
  }
  
  /* CASE DETAIL */
  .case-detail {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 120px 0;
  }
  .case-detail figure {
    display: block;
    margin-bottom: 50px;
  }
  .case-detail figure img {
    width: 100%;
  }
  .case-detail h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .case-detail h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .case-detail h3 {
    font-size: 50px;
    font-weight: 800;
    margin-top: 100px;
  }
  .case-detail p {
    display: block;
    margin-bottom: 50px;
  }
  
  /* FEATURED SERVICES */
  .featured-services {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
    color: #fff;
  }
  .featured-services .col-lg-3:nth-child(2n+2) {
    margin-top: 134px;
  }
  .featured-services figure {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    margin: 15px 0;
    border: 1px solid rgba(255, 255, 255, 0.05);
    padding: 30% 0;
  }
  .featured-services figure:hover {
    background: #fff;
  }
  .featured-services figure:hover img {
    margin: 0;
    filter: invert(100%);
  }
  .featured-services figure:hover figcaption {
    color: #282828;
  }
  .featured-services figure img {
    opacity: 0.1;
    padding: 0 35%;
    filter: blur(10px);
  }
  .featured-services figure figcaption {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    text-align: center;
  }
  .featured-services figure figcaption span {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .featured-services figure figcaption h4 {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
  }
  
  /* RECENT NEWS */
  .recent-news {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  .recent-news .row {
    margin-left: -25px;
    margin-right: -25px;
  }
  .recent-news .col-lg-4 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .recent-news .col-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .recent-news .inner h3 {
    font-size: 20px;
  }
  .recent-news .content-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 80px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(40, 40, 40, 0.1);
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -ms-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    position: relative;
  }
  .recent-news .content-box:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    width: 0;
    transition: width 0s ease, background .25s ease;
  }
  .recent-news .content-box:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -1px;
    height: 1px;
    width: 0;
    background: #282828;
    transition: width .25s ease;
  }
  .recent-news .content-box:hover:before {
    width: 100%;
    background: #282828;
    transition: width .25s ease;
  }
  .recent-news .content-box:hover:after {
    width: 100%;
    background: transparent;
    transition: all 0s ease;
  }
  .recent-news .content-box.selected {
    padding-bottom: 80px;
  }
  .recent-news .content-box.selected h3 {
    font-size: 4vw;
    font-weight: 600;
  }
  .recent-news .content-box small {
    opacity: 0.5;
    margin-bottom: 10px;
  }
  .recent-news .content-box h3 {
    display: block;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .recent-news .content-box h3 a {
    display: block;
  }
  .recent-news .content-box h3 a:hover {
    text-decoration: none;
  }
  .recent-news .content-box span {
    display: block;
    font-weight: 600;
  }
  
  /* OUR AWARDS */
  .our-awards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 65px 0 73px;
    background-image: linear-gradient(to right, #52c8e9, #7158BE);
  }
  .our-awards .titles {
    text-align: center;
    padding: 0 20%;
  }
  .our-awards ul {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .our-awards ul li {
    width: 20%;
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .our-awards ul li:last-child figure {
    border-right: 0;
  }
  .our-awards ul li figure {
    width: 100%;
    margin-bottom: 40px;
    padding: 0 20%;
    border-right: 1px solid rgba(255, 255, 255, 0.329);
  }
  .our-awards ul li h5 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
    color: #fff;
  }
  .our-awards ul li small {
    display: block;
    opacity: 0.6;
    margin-bottom: 5px;
  }
  .our-awards ul li .odometer {
    font-size: 40px;
    font-weight: 800;
  }
  .odometer-inside {
    color: $beyaz-renk;
  }
  
  /* SHOWREEL */
  .showreel {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
  }
  .showreel .video {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .showreel .video h2 {
    width: 100%;
    height: 200px;
    line-height: 200px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -100px;
    text-align: center;
    color: #fff;
    font-size: 4vw;
    font-weight: 800;
  }
  .showreel .video video {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    float: left;
  }
  
  /* TEAM */
  .team {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 150px 0;
    color: #fff;
  }
  .team figure {
    display: block;
    margin: 0;
  }
  .team figure img {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .team figure figcaption {
    display: block;
    margin: 0;
  }
  .team figure figcaption span {
    display: block;
    font-size: 14px;
    opacity: 0.5;
    margin-bottom: 10px;
  }
  .team figure figcaption h5 {
    display: block;
    font-weight: 600;
    margin-bottom: 0;
  }
  .team figure figcaption a {
    color: #fff;
    margin-right: 10px;
    margin-bottom: 10px;
  }
