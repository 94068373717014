// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Manrope", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$color-danger: #ee0000;
$primary: #4B9BD8;
$text-color: #282828;

$anarenk-acik-kirmizi: #18191a;
$anarenk-normal-kirmizi: #141414;
$anarenk-koyu-kirmizi: #000000;

$anarenk-acik-altin:#c7b494;
$anarenk-normal-altin:#b29a7b;
$anarenk-koyu-altin:#7e6b52;
$anarenk-acik-pembe:#E9B2D1;
$anarenk-normal-pembe:#DE6FAA;
$anarenk-koyu-pembe:#d366a0;
$anarenk-acik-mavi:#33c4e7;
$anarenk-normal-mavi:#259cb9;
$anarenk-koyu-mavi:#0c52aa;
$anarenk-acik-blue: #135886;
$anarenk-normal-blue: #064066;
$anarenk-koyu-blue: #04314e;

$artemis-beige-1: #f5f3f0;
$artemis-beige-2: #eeebe6;
$artemis-beige-3: #e7e1d9;
$artemis-red-1: #ba344e;
$artemis-red-2: #c85d72;
$artemis-red-3: #dd9aa7;
$artemis-red-4: #ebc3cb;
$artemis-blue-1-1: #13357a;
$artemis-blue-1-2: #435e95;
$artemis-blue-1-3: #899bbd;
$artemis-blue-1-4: #b9c3d8;
$artemis-blue-2-1: #0074b5;
$artemis-blue-2-2: #3690c5;
$artemis-blue-2-3: #80badb;
$artemis-blue-2-4: #b3d5e7;
$artemis-blue-3-1: #21beff;
$artemis-blue-3-2: #5bc5f2;
$artemis-blue-3-3: #96d6f5;
$artemis-blue-3-4: #bfe5f9;

$primary: $artemis-blue-1-1;
