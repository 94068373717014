.form__grup {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    &.required {
        &:after {
            content: '*';
            position: absolute;
            width: 90%;
            display: block;
            text-align: right;
            top: 0;
            padding: 0.5rem 1rem;
            color: $color-danger;
        }
        &.after-w-100 {
            &:after {
                width: 100%;
            }
        }
    }
    input, .form__input {
        position: relative;
        z-index: 1;
    }
    .form__label {
        z-index: 1;
        position: relative;
        flex-basis: 100%;
    }

    &.select {
        i.fa {
            position: absolute;
            right: 10%;
            top: 0;
            font-size: 1.5rem;
            padding: 1.3rem 3rem;
            line-height: inherit;
        }
        select {
            appearance: none !important;
            -webkit-appearance: none !important;
        }
    }
    .invalid-feedback {
        flex-basis: 100%;
    }
}
.btn-red {
    background-color: $artemis-red-1 !important;
}
input, select {
    &[readonly], &[disabled] {
        &:not(.drp):not(#necessary-cookie-consent) {
            background-color: #eeebe6 !important;
            opacity: 0.5 !important;
        }
    }
}
