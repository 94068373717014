span {
    &.user-status {
        display: block;
        width: 16px;
        height: 16px;
        margin: 4px auto 3px;
        border-radius: 16px;

        &.active {
            background-color: #198754;
        }

        &.pending {
            background-color: #fd7e14;
        }

        &.account-ban {
            background-color: #dc3545;
        }

    }
}

table.dataTable td, table.dataTable th {
    border: 0 !important;
    padding: 1rem 1.5rem !important;
}

#user-search-table {
    thead {
        tr {
            height: 0 !important;
            td {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }
    }
}

#recommendation-search-table {
    td {
        width: 20%;
        vertical-align: middle;
        &:nth-child(1) {
            width: 10%;
        }
        &:nth-child(7) {
            width: 10%;
        }
        //&:nth-child(6) {
        //    white-space: nowrap;
        //}
    }
    &[data-dashboard="true"] {
        td {
            width: 15%;
        }
    }

}

table.dataTable.table-striped > tbody > tr.odd > * {
    box-shadow: inset 0 0 0 9999px #F5F3F0;
}
