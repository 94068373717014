
//anasayfa slider için
/* HEADER */
.header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    z-index: 888;
    background-image: linear-gradient(to right, #4B9BD8, #7158BE);
  }
  .header .navbar {
    width: 100%;
    margin-bottom: 0;
  }
  .header .page-title {
    width: 100%;
    margin-bottom: 124px;
  }
  .header .page-title h2 {
    font-weight: 700;
    font-size: 3.5vw;
    margin-bottom: 1px;
    text-align: center;
    background-image: linear-gradient(to right, #ffffff, #fdfdfd);
    -webkit-background-clip: text;
    color: transparent;
  }
  .header .page-title h4 {
    text-align: center;
    line-height: 1.7;
    text-align: center;
    line-height: 1.7;
    background-image: linear-gradient(to right, #ffffff, #fdfdfd);
    -webkit-background-clip: text;
    color: transparent;
  }
  .header .headlines {
    width: 100%;
    margin-bottom: 100px;
    margin-top: 50px;
  }
  .header .headlines h1 {
    font-size: 8vw;
    font-weight: 800;
    line-height: 1;
    margin: 0;
    padding-bottom: 5px;
  }

  
.header__slider-alani{
    position: absolute;
    top:40%;
    left:42%;
    transform: translate(-30%,-30%);
    text-align: center;

    @include responsive(telefon) {
    position: absolute;
    top: 20%;
    left: 35%;
    transform: translate(-30%, -30%);
    text-align: center;
    }
}


//header area ----------------------------------------------------------------------------------

.header-area {
    height: 36vh;
    background: #000000;
    background-size: cover;
    background-position: top;
    position: relative;

    @media only screen and (max-width: 1024px) {
        
    }

    @media only screen and (max-width: 812px) {
    }
    
    

    @include responsive(telefon) {
        height: 28vh;
    }

    @include responsive(tablet-kucuk) {

    }
}



.header-area-top{
    position: absolute;
    top:30%;
    left:47%;
    transform: translate(-30%,-30%);
    text-align: center;
    z-index: inherit;

    @include responsive(telefon) {
        position: absolute;
        top: 41%;
        left: 40%;
        transform: translate(-30%, -30%);
        text-align: center;
    }

    @include responsive(tablet-kucuk) {
        position: absolute;
        top: 70%;
        left: 45%;
        transform: translate(-30%, -30%);
        text-align: center;
    }

}

.header-area-top-titles{
color: $siyah-renk;
text-transform: uppercase;
margin-bottom: 43rem;
backface-visibility: hidden;

@include responsive(tablet-kucuk) {
    font-size: 8rem;
}

&--1{
    display: block;
    font-size: 4rem;
    font-weight: 900;
    letter-spacing: 0.3rem;
    
    animation-name: solahareket;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;

    @include responsive(tablet-kucuk) {
        font-size: 4rem;
    }
    @include responsive(tablet-kucuk) {
        font-size: 4rem;
    }

    @include responsive(tablet-buyuk) {
        font-size: 4rem;
    }
    }
    
&--2{
    display: block;
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 0rem;
    font-weight: 500;
    
    animation: sagahareket 1s ease-out;

    @include responsive(tablet-kucuk) {
        font-size: 3rem;
        font-weight: 500;
    }
    }

}


.header-area-top-blog{
    position: absolute;
    top:70%;
    left:48%;
    transform: translate(-30%,-30%);
    text-align: center;

    @include responsive(telefon) {
        position: absolute;
        top: 41%;
        left: 40%;
        transform: translate(-30%, -30%);
        text-align: center;
    }

    @include responsive(tablet-kucuk) {
        position: absolute;
        top: 70%;
        left: 45%;
        transform: translate(-30%, -30%);
        text-align: center;
    }

}

//contact send area ----------------------------------------------------------------------------------

.header-contact-area {
    height: 68vh;
    background:$siyah-renk;
    background-size: cover;
    background-position: top;
    position: relative;
    z-index: inherit;

    @media only screen and (max-width: 1024px) {
        
    }

    @media only screen and (max-width: 812px) {
    }
    
    

    @include responsive(telefon) {
        height: 69vh;
    }

    @include responsive(tablet-kucuk) {

    }
}



.header-contact-area-top{
    position: absolute;
    top:60%;
    left:43%;
    transform: translate(-30%,-30%);
    text-align: center;

    @include responsive(telefon) {
        position: absolute;
        top: 41%;
        left: 40%;
        transform: translate(-30%, -30%);
        text-align: center;
    }

    @include responsive(tablet-kucuk) {
        position: absolute;
        top: 60%;
        left: 40%;
        transform: translate(-30%, -30%);
        text-align: center;
    }

}

.header-contact-area-top-titles{
color: $beyaz-renk;
text-transform: uppercase;
margin-bottom: 5rem;
backface-visibility: hidden;

@include responsive(tablet-kucuk) {
    font-size: 5rem;
}

&--1{
    display: block;
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 1rem;
    
    animation-name: solahareket;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;

    @include responsive(tablet-kucuk) {
        font-size: 4rem;
    }
    @include responsive(tablet-kucuk) {
        font-size: 4rem;
    }

    @include responsive(tablet-buyuk) {
        font-size: 4rem;
    }
    }
    
&--2{
    display: block;
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 0rem;
    font-weight: 500;
    
    animation: sagahareket 1s ease-out;

    @include responsive(tablet-kucuk) {
        font-size: 3rem;
        font-weight: 500;
    }
    }

}
