.galeri {
  position: relative;
      &__gorsel {
       width: 100%;
       /*height: 55rem;*/
       outline-offset: 2rem;
       z-index: 10;

       @include responsive(tablet-kucuk) {
           float: left;
           height: 100%;
           box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .2);
       }
          &--3 {

              left:20%;
              margin-top: -1rem;
              /*top: 10rem;*/

              @media only screen and (max-width: 1024px) {
                margin-top: 1rem;
              }

              @include responsive(tablet-kucuk) {
                  margin-top: 31rem;
              }

              @include responsive(telefon) {
                margin-top: 178px;
              }
              @media only screen and (max-width: 390px) {
                margin-top: 178px;
              }
              @media only screen and (max-width: 700px) {
                margin-top: 506px;
              }
              @media only screen and (max-width: 820px) {
                margin-top: 211px;
              }
          }
          &--8 {

            left:20%;
            margin-top: -1rem;
            /*top: 10rem;*/

            @include responsive(tablet-kucuk) {
                margin-top: 16rem;
            }
      }
          &--4 {

              left:20%;
              margin-top: -5rem;
              /*top: 10rem;*/

              @include responsive(tablet-kucuk) {
                  margin-top: 30rem;
              }
          }
      }
      &:hover &__gorsel:not(:hover) {

      }

  }

  // galeri proje detay

  .galeriprojedetay {
      position: relative;
          &__gorsel {
           width: 100%;
           height: 40rem;
           outline-offset: 2rem;
           z-index: 10;
           transition: all .2s;

           @include responsive(tablet-kucuk) {
               float: left;
               height: 100%;
               box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .2);
           }
              &--3 {

                  left:20%;
                  margin-top: -1rem;
                  /*top: 10rem;*/

                  @include responsive(tablet-kucuk) {
                      margin-top: .1rem;
                  }
              }

              &--4 {

                  left:20%;
                  margin-top: -5rem;
                  /*top: 10rem;*/

                  @include responsive(tablet-kucuk) {
                      margin-top: 11rem;
                  }
              }
          }
          &:hover &__gorsel:not(:hover) {

              transform: scale(.95);
          }

      }

  // Galeri Popup

  .galeri {
      position: relative;
          &__popup {
           width: 100%;
           height: 100%;
           outline-offset: 2rem;
           z-index: 10;
           transition: all .2s;

           @include responsive(tablet-kucuk) {
               float: left;
               height: 100%;
               box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .2);
           }
              &--1 {

                  margin-top: -1rem;
                  /*top: 10rem;*/

                  @include responsive(tablet-kucuk) {
                      margin-top: -1rem;
                  }
              }
              &--2 {

                  margin-top: -1rem;
                  /*top: 10rem;*/

                  @include responsive(tablet-kucuk) {
                      margin-top: -26rem;
                  }
              }
          }
          &:hover &__gorsel:not(:hover) {

          }
  }

  // 404 Galeri

  .galeri-404 {
      position: relative;
      &__gorsel {
      width: 100%;
      height: 55rem;
      outline-offset: 2rem;
      z-index: 10;
      transition: all .2s;

      @include responsive(tablet-kucuk) {
          float: left;
           height: 100%;
           box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .2);
      }
      &--3 {

          left:20%;
          margin-top: -8rem;
          /*top: 10rem;*/

          @include responsive(tablet-kucuk) {
              margin-top: -1rem;
              }
          }
      }

      &:hover &__gorsel:not(:hover) {

          transform: scale(.95);
      }

  }

  // gallery zoom

  $time: 0.3s;

  .gallery {
      display: grid;
      grid-template-rows: repeat(1, 20vh);
      grid-template-columns: repeat(10, 20.3vh);
      background-color: $beyaz-renk;
      border-radius: 10px;
      padding: 0.25em;
      cursor: zoom-in;
      // grid-gap: .25em;

      @media only screen and (max-width: 1024px) {
      display: grid;
      grid-template-rows: repeat(1, 20vh);
      grid-template-columns: repeat(10, 7vh);
      background-color: #fff;
      border-radius: 10px;
      padding: 0.25em;
      cursor: zoom-in;
      }

      @include responsive(telefon) {

      display: grid;
      grid-template-rows: repeat(2, 20vh);
      grid-template-columns: repeat(5, 20vw);
      background-color: #fff;
      border-radius: 10px;
      padding: 0.25em;
      cursor: zoom-in;
      }

      @include responsive(tablet-kucuk) {

      display: grid;
      grid-template-rows: none;
      grid-template-columns: none;
      background-color: #fff;
      border-radius: 10px;
      padding: 0.25em;
      cursor: zoom-in;
      }
    }

    .gallery__img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: relative;
      box-shadow: 0 0 0 #0000;
      opacity: 1;


      &:first-child {
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      @media (min-width: 40em) and (max-width: 59.99em) {
        &:first-child {
          border-radius: 10px 0 0 0;
        }
        &:nth-child(5) {
          border-radius: 0 10px 0 0;
        }
        &:nth-child(6) {
          border-radius: 0 0 10px 0;
        }
        &:last-child {
          border-radius: 0 0 0 10px;
        }
      }

      @media (min-width: 60em) {
        &:first-child {
          border-radius: 10px 0 0 10px;
        }
        &:last-child {
          border-radius: 0 10px 10px 0;
        }
      }

      &:hover {
        opacity: 1;
        z-index: 1;
        box-shadow: 1em 1em 1em #0004;
        filter: sepia(0%) hue-rotate(0deg);
        border-radius: 5px;
        width: 300%;
        height: 300%;
        left: -100%;
        top: -100%;

        @media (min-width: 40em) {
          width: 250%;
          height: 500%;
          left: -75%;
          top: -200%;
        }

        @media (min-width: 40em) and (orientation: portrait) {
          width: 300%;
          height: 300%;
          left: -100%;
          top: -100%;
        }

        @media (min-width: 60em) {
          width: 350%;
          height: 150%;
          left: -75%;
          top: -25%;

          ~ img {
            left: 175%;
          }
        }

        @media (min-width: 60em) and (orientation: landscape) {
          width: 300%;
          height: 300%;
          left: -75%;
          top: -100%;

          ~ img {
            left: 100%;
          }
        }
      }
    }

  // media in

    .grid-container {
      columns: 5 200px;
      column-gap: 1.5rem;
      width: 90%;
      margin: 0 auto;
      div {
        width: 150px;
        margin: 0 1.5rem 1.5rem 0;
        display: inline-block;
        width: 100%;
        border: solid 2px black;
        padding: 5px;
        box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
        border-radius: 5px;
        transition: all .25s ease-in-out;
        &:hover img {
          filter: grayscale(0);
        }
        &:hover {
          border-color: $anarenk-normal-kirmizi;
        }
        img {
          width: 100%;
          border-radius: 5px;
          transition: all .25s ease-in-out;
        }
      }
    }

  /* The Modal (background) */
  .modal {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
  }

  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    /*width: 90%;*/
    max-width: 1200px;
  }

  /* The Close Button */
  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }

  /* Hide the slides by default */
  .mySlides {
    display: none;
  }

  /* Next & previous buttons */
  .prev:not(.paginate_button.page-item),
  .next:not(.paginate_button.page-item) {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;

    @media only screen and (min-width: 700px) and (max-width: 812px){
      margin-top: -50px;
    }

    @include responsive(telefon) {
      margin-top: -207px;
    }

  }

  /* Position the "next button" to the right */
  .next:not(.paginate_button.page-item) {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:not(.paginate_button.page-item):hover,
  .next:not(.paginate_button.page-item):hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  /* Caption text */
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }

  img.demo {
    opacity: 0.6;
  }

  .active,
  .demo:hover {
    opacity: 1;
  }

  img.hover-shadow {
    transition: 0.3s;
  }

  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  img.team-gorsel {
}

  img.haber-gorsel {
}

img.yorum-gorsel {
}

img.ha-car {
  margin-top: -223px;
}
img.hm-mask-car {
  margin-top: -143px;
}
