#cookie-consent {

    .settings {
        //font-size: .8rem;

        > div {
            margin-bottom: 1rem;
        }

        .form-switch {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 0;
            font-weight: bold;
            margin-bottom: .6rem;

            .form-check-input {
                height: 1.4rem;
                width: calc(2rem + 0.75rem);
                border-radius: 3rem;
            }
        }
    }
}


