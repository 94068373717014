@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 100;
    src: url('../webfonts/Manrope-ExtraLight.ttf');
}
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 200;
    src: url('../webfonts/Manrope-Light.ttf');
}
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    src: url('../webfonts/Manrope-Regular.ttf');
}
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/Manrope-Medium.ttf');
}
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    src: url('../webfonts/Manrope-SemiBold.ttf');
}
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    src: url('../webfonts/Manrope-Bold.ttf');
}
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/Manrope-ExtraBold.ttf');
}
