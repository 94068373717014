/* clearfix */

.owl-carousel .owl-wrapper:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: ".";
  line-height: 0;
}


/* display none until init */

.owl-carousel {
  position: relative;
  display: none;
  width: 100%;
  -ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
  position: relative;
  display: none;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel2 .owl-wrapper-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: inline-flex;
}

.owl-carousel2 .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
  float: left;

}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer;
}

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


/* mouse grab icon */

.grabbing {
  cursor: url(../images/grabbing.png) 8 8, move;
}


/* fix */

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  display: inline-table;
}

.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center;
}


/*
 *  Owl Carousel CSS3 Transitions
 *  v1.3.2
 */

.owl-origin {
  -webkit-perspective: 1200px;
  -webkit-perspective-origin-x: 50%;
  -webkit-perspective-origin-y: 50%;
  -moz-perspective: 1200px;
  -moz-perspective-origin-x: 50%;
  -moz-perspective-origin-y: 50%;
  perspective: 1200px;
}


/* fade */

.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut .7s both ease;
  -moz-animation: fadeOut .7s both ease;
  animation: fadeOut .7s both ease;
}

.owl-fade-in {
  -webkit-animation: fadeIn .7s both ease;
  -moz-animation: fadeIn .7s both ease;
  animation: fadeIn .7s both ease;
}


/* backSlide */

.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}

.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}


/* goDown */

.owl-goDown-out {
  -webkit-animation: scaleToFade .7s ease both;
  -moz-animation: scaleToFade .7s ease both;
  animation: scaleToFade .7s ease both;
}

.owl-goDown-in {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both;
}


/* scaleUp */

.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both;
}

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both;
}


/* Keyframes */


/*empty*/

@-webkit-keyframes empty {
  0% {
    opacity: 1
  }
}

@-moz-keyframes empty {
  0% {
    opacity: 1
  }
}

@keyframes empty {
  0% {
    opacity: 1
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px);
  }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%);
  }
}

@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px);
  }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%);
  }
}

@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-500px);
  }
  75% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%);
  }
  100% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%);
  }
}

@-webkit-keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0);
  }
}

@-moz-keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0);
  }
}

@keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    transform: translateZ(-500px) translateX(200%);
  }
  75% {
    opacity: .5;
    transform: translateZ(-500px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0);
  }
}

@-webkit-keyframes scaleToFade {
  to {
    opacity: 0;
    -webkit-transform: scale(.8);
  }
}

@-moz-keyframes scaleToFade {
  to {
    opacity: 0;
    -moz-transform: scale(.8);
  }
}

@keyframes scaleToFade {
  to {
    opacity: 0;
    transform: scale(.8);
  }
}

@-webkit-keyframes goDown {
  from {
    -webkit-transform: translateY(-100%);
  }
}

@-moz-keyframes goDown {
  from {
    -moz-transform: translateY(-100%);
  }
}

@keyframes goDown {
  from {
    transform: translateY(-100%);
  }
}

@-webkit-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}

@-moz-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}

@keyframes scaleUpFrom {
  from {
    opacity: 0;
    transform: scale(1.5);
  }
}

@-webkit-keyframes scaleUpTo {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
}

@-moz-keyframes scaleUpTo {
  to {
    opacity: 0;
    -moz-transform: scale(1.5);
  }
}

@keyframes scaleUpTo {
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}


/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div {
  display: inline-block;
  margin: 5px;
  padding: 3px 10px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #869791;
  color: #FFF;
  font-size: 12px;
  opacity: 0.5;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
}


/* Clickable class fix problem with hover on touch devices */


/* Use it for non-touch hover action */

.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  text-decoration: none;
  opacity: 1;
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
}


/* Styling Pagination*/

.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
  display: block;
  margin: 5px 7px;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791;
  opacity: 0.5;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  opacity: 1;
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
}


/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers {
  padding: 2px 10px;
  width: auto;
  height: auto;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  color: #FFF;
  font-size: 12px;
}


/* preloading images */

.owl-item.loading {
  min-height: 150px;
  background: url(../images/AjaxLoader.gif) no-repeat center center;
}


/* CUSTOM USER OPTIONS */

.carousel-container {
  width: 100%;
  margin-top: 50px;
}

.item {
  margin: 0;
  background: #fff;
  color: #f0f0f0;
  border-radius: 10px;
  text-align: center;
  font-family: "Manrope", sans-serif;

  @include responsive(tablet-kucuk) {
    font-size: 4.5rem;
  }

}

.item img {
  width: 100%;
}

.item h4 {
  margin-top: 27px;
  color: $anarenk-koyu-mavi;
  font-size: 2rem;
  font-family: "poppins";

  @include responsive(tablet-kucuk) {
    font-size: 6.7rem;
}
}

.item h6 {
  padding-bottom: 30px;
  color: #575a5a;
  font-size: 1.5rem;
  font-family: "poppins";

  @include responsive(tablet-kucuk) {
    font-size: 3.8rem;
}
}

.customNavigation {
  display: none;
  margin-top: -25px;
  text-align: center;
}

.customNavigation .btn {
  margin: 0;
  position: relative;
  display: inline-block;
  overflow: visible;
  padding: 0.8em 1.1em;
  width: 50%;
  float: left;
  border: 0;
  box-shadow: none;
  color: #fff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 20px;
  cursor: crosshair;
  -webkit-transition: all .1s ease-in 0s;
  -moz-transition: all .1s ease-in 0s;
  -o-transition: all .1s ease-in 0s;
  -webkit-font-smoothing: antialiased;
  background: #333;
}

.customNavigation .btn:hover {
  background: #222;
}

.hoverfx {
  display: block;
  overflow: hidden;
  text-align: center;
}

.hoverfx img {
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 1px;
  margin-left: 1px;
  filter: alpha(opacity=40);
  opacity: 0.6;
  transition: all .5s ease-out;
  border-color: #cecece;
  border-style: solid;
  border-width: 1.5rem;

  &:hover {
  opacity: 1;
  transform: translateY(5px);

  }
}

.hoverfx:before {
  display: inline-block;
  padding-top: 80%;
  content: '';
  vertical-align: middle;
}

.hoverfx .figure {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 12px;
  max-width: 60%;
  border: 3px solid #e9e9e9;
  color: #ecf0f1;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 1rem;
  opacity: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;

}

.hoverfx .overlay {
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 50%;
  margin-left: 5px;
  border-radius: 10px;
  background: #fff;
  opacity: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  display: none;
}

.item:hover .figure,
.item:hover .overlay {
  opacity: 1;
}

.owl-wrapper {
  width: 100%; left: 0px; display: block;
}

// projeler alanı

.containerTitles{
  width: 100%;
  text-align: center;
  margin: 20px 0;
}
.containerTitles h1{
  font-size: 3rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.containerTitles h2{
  font-weight: normal;
}
.component-systemTabs{
  width: 100%;
  display: flex;
}
.component-systemTabs .tabs-container {
  margin: 0 auto;
  width: 100%;
}
.component-systemTabs .tabs-container ul.tabs {
  margin: 20px 0;
  padding: 0px;
  list-style: none;
  text-align: center;
}
.component-systemTabs .tabs-container ul.tabs li {
  background: none;
  color: rgba(90,90,90, 1);
  display: inline-block;
  padding: 10px 25px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 4px solid #d7d7d7;
  font-size: 1.6rem;
  transition: all 0.5s ease;

  @include responsive(tablet-kucuk) {
    font-size: 4.1rem;
  }
}
.component-systemTabs .tabs-container ul.tabs li:hover{
  color: $anarenk-koyu-kirmizi;
}
.component-systemTabs .tabs-container ul.tabs li.current {
  color: $anarenk-koyu-kirmizi;
  border-bottom: 4px solid $anarenk-koyu-kirmizi;
  transition: all 0.5s ease;
}
.component-systemTabs .tab-content {
  display: none;
  padding: 15px;
  width: 100%;
}
.component-systemTabs .tab-content.current {
  display: block;
}
.component-systemTabs .cards{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.component-systemTabs .cards .card{
  width: 454px;
  height: 507px;
  margin: 5px;
  position: relative;
}
.component-systemTabs .cards .card img{
  height: 100%;
  width: 100%;
  max-width: 454px;
  max-height: 507px;
}
.component-systemTabs .cards .card .cardContent{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  background-color: rgba(29, 29, 30, 0.555);
  top:0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px;
  border: .7rem solid transparent;
  border-image: linear-gradient(to right, #000000, #202020);
  border-image-slice: 1;
  cursor: pointer;
  transition: all 0.3s ease;
}
.component-systemTabs .cards .card .cardContent h2{
  color: rgba(255,255,255, 1);
  font-size: 2rem;
}
.component-systemTabs .cards .card .cardContent button{
  border: 0;
  background-color: #000000;
  padding: 7px 10px;
  width: 100px;
  color: rgba(255,255,255, 1);
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: 900;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  opacity: 0;
  transition: 0.3s ease;
}
.component-systemTabs .cards .card .cardContent button:hover{
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0, .3);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0, .3);
  box-shadow: 0px 0px 9px 0px rgba(0,0,0, .3);
  font-size: 1.25rem;
}
.component-systemTabs .cards .card .cardContent:hover{
  background-color: rgba(0,0,0, .2);
}
.component-systemTabs .cards .card .cardContent:hover button{
  opacity: 1;
}
.tabs-content{
  display: none;
  background: #ffffff;
  padding: 0px;
  list-style: none;
  text-align: center;
}
  #tabs li{
    display: inline-block;
    margin: 5px;
    padding: 5px;
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    color: #4c99d7;
    border-bottom: 4px solid #4c99d7;
    transition: all 0.5s ease;
    padding: 15px;

    @include responsive(tablet-kucuk) {
      font-size: 4.1rem;
    }
  }
  .button-renk{
    color: #7158BE;
    border-bottom: 4px solid #7158BE;
    transition: all 0.5s ease;
    background-image: linear-gradient(to right, #4B9BD8, #4b9bd8);
    -webkit-background-clip: text;
    color: transparent;}
    ul#tabs {
      margin: 20px 0;
      padding: 0px;
      list-style: none;
      text-align: center;
  }

/* Count */
.counter
{
    text-align: center;
}

.counter-count
{
    font-size: 50px;
    font-weight: bold;
    position: relative;
    color: #ffffff;
    text-align: center;
    display: inline-block;
}

h3.counter
{
    text-align: center;
    color: #ffffff;
}
span.plus {
  color: #ffffff;
  font-size: 2.5em;
}
