//renkler

$anarenk-acik-kirmizi: #5986D0;
$anarenk-normal-kirmizi: #4B9BD8;
$anarenk-koyu-kirmizi: #7158BE;

$anarenk-acik-altin:#c7b494;
$anarenk-normal-altin:#b29a7b;
$anarenk-koyu-altin:#7e6b52;
$anarenk-acik-pembe:#E9B2D1;
$anarenk-normal-pembe:#DE6FAA;
$anarenk-koyu-pembe:#d366a0;
$anarenk-acik-mavi:#33c4e7;
$anarenk-normal-mavi:#259cb9;
$anarenk-koyu-mavi:#0c52aa;
$anarenk-acik-blue: #135886;
$anarenk-normal-blue: #064066;
$anarenk-koyu-blue: #04314e;



//body renkleri
$anarenk-yazi: #797979;

//font renkleri

$gri-renk-7: #797979;
$gri-renk-6: #484747;
$gri-renk-5: #676767;
$gri-renk-4: #4a4949;
$gri-acik-renk: #f7f7f7;
$gri-koyu-renk: #999;
$gri-yazi-rengi:#777;
$gri-renk-3: rgb(37, 36, 36);
$beyaz-renk:#fff;
$siyah-renk:#000;
$gri-renk: #141414;
$gri-renk-2: #eee;
$kirmizi-renk: rgb(235, 12, 12);

//tablo boyutları
$tablo-genislik:114rem;
$tablo-yatay-bottom:8rem;
$tablo-yatay-bottom-kucuk:6rem;
$tablo-arasi-bosluk:6rem;

//yazı boyutları
$standart-yazi-boyutu:1.6rem;

//özellik rengi
$siyah-renk-1:#040404;